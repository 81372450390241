import styled from "styled-components";
import React from "react";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #25101099;
  z-index: 9999;
  color: ${(props) => props.theme.colours.black};
`;

const ContentPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const ContentWrap = styled.div`
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  padding: 16px;
`;

const Modal = ({ children, open }) => {
  return open ? (
    <Overlay>
      <ContentPosition>
        <ContentWrap>{children}</ContentWrap>
      </ContentPosition>
    </Overlay>
  ) : null;
};

export default Modal;
