import { createSelector, createSlice } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    preferredCameraId: null,
    updateAvailable: false,
    branchInfo: {},
    lastUpdated: null,
    currentVisitId: null,
    previousVisitId: null,
    currentQuestionId: null,
    currentCategory: null,
    dismissedModals: {},
    permissionModalShown: false,
  },
  reducers: {
    setPreferredCameraId: (state, action) => {
      state.preferredCameraId = action.payload;
    },
    setUpdateAvailable: (state, action) => {
      state.updateAvailable = action.payload;
    },
    setBranchInfo: (state, action) => {
      state.branchInfo = action.payload;
    },
    setLastUpdated: (state) => {
      state.lastUpdated = new Date().getTime();
    },
    setCurrentVisitId: (state, action) => {
      state.currentVisitId = action.payload;
    },
    setPreviousVisitId: (state, action) => {
      state.previousVisitId = action.payload;
    },
    setCurrentQuestionId: (state, action) => {
      state.currentQuestionId = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setModalDismissal: (state, action) => {
      try {
        const questionId = action.payload;
        state.dismissedModals[questionId] = true;
      } catch (e) {
        console.error(e);
      }
    },
    setPermissionModalShown: (state, action) => {
      state.permissionModalShown = action.payload;
    },

    clearSettings: (state) => {
      // camera preferences & branch info can remain

      state.lastUpdated = null;
      state.currentVisitId = null;
      state.previousVisitId = null;
      state.currentQuestionId = null;
      state.currentQuestionId = null;
    },
  },
});

export const {
  setPreferredCameraId,
  setUpdateAvailable,
  setBranchInfo,
  setLastUpdated,
  setCurrentVisitId,
  setPreviousVisitId,
  setCurrentQuestionId,
  setCurrentCategory,
  setModalDismissal,
  clearSettings,
  setPermissionModalShown,
} = settingsSlice.actions;

const selectSlice = (state) => state?.[settingsSlice.name];

export const selectPreferredCameraId = createSelector(
  selectSlice,
  (slice) => slice.preferredCameraId
);

export const selectUpdateAvailable = createSelector(
  selectSlice,
  (slice) => slice.updateAvailable
);

export const selectVisitLastUpdated = createSelector(
  selectSlice,
  (state) => state.lastUpdated
);

export const selectCurrentVisitId = createSelector(
  selectSlice,
  (state) => state.currentVisitId
);

export const selectPreviousVisitId = createSelector(
  selectSlice,
  (state) => state.previousVisitId
);

export const selectCurrentQuestionId = createSelector(
  selectSlice,
  (state) => state?.currentQuestionId
);

export const selectCurrentCategory = createSelector(
  selectSlice,
  (state) => state.currentCategory
);

export const selectDismissedModal = createSelector(
  selectSlice,
  (state) => state?.dismissedModals ?? {}
);

export const selectIsSelectedQuestionDismissedModal = createSelector(
  selectDismissedModal,
  selectCurrentQuestionId,

  (dismissedModals, currentQuestionId) => dismissedModals[currentQuestionId]
);

export const selectPermissionModalShown = createSelector(
  selectSlice,
  (state) => state?.permissionModalShown
);

export default settingsSlice;
