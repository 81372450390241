import { Button } from "@redwigwam/redalerts.elements.button";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Meta from "../components/Meta";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  height: 100%;
`;

const Page404 = () => {
  const history = useHistory();
  const handleReturn = () => {
    history.push("/");
  };
  return (
    <Container>
      <Meta title="404" />
      <p>Oops, something has gone wrong.</p>
      <Button onClick={handleReturn} primary text="Return home" />
    </Container>
  );
};

export default Page404;
