import React from "react";
import styled from "styled-components";
import { Paper } from "@redwigwam/redalerts.elements.paper";
import CheckIcon from "@material-ui/icons/Check";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";

const AnswerCopy = styled.span``;

const StyledPaper = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px;
  border: 2px solid ${(props) => (props.disabled ? "#FAF9F9" : "white")};
  background-color: ${(props) => (props.disabled ? "#FAF9F9" : "white")};

  ${(props) =>
    props.selected && `border:2px solid ${props.theme.colours.purple};`}

  ${(props) =>
    props.icon &&
    `
    padding-right: 0;
`}
`;

const RadioButtonStyling = styled.span`
  display: block;
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border-radius: 50%;
  border: 2px solid ${(props) => props?.theme?.colours?.purple};
`;

const CheckboxStyling = styled.span`
  display: block;
  position: relative;
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: white;
  border: 2px solid ${(props) => props?.theme?.colours?.purple};

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: ${(props) => (props.disabled ? "#FAF9F9" : "white")};
  }
`;

const Input = styled.input.attrs((props) => ({
  type: props.type,
}))`
  display: none;

  &:checked ~ ${RadioButtonStyling} {
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin: 1px;
      background: ${(props) => props?.theme?.colours?.purple};
    }
  }

  &:checked ~ ${CheckboxStyling} {
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: ${(props) => props?.theme?.colours?.purple};
    }
  }
`;

const Label = styled.label`
  width: 100%;
`;

const ThumbsUpIcon = styled(ThumbUpOutlinedIcon)`
  && {
    width: 2.5em;
    height: 2.5em;
    transform: scaleX(-1);
    opacity: ${(props) => (props.disabled ? "0.05" : "0.15")};
    fill: ${(props) => props?.theme?.colours?.purple};
  }
`;

const TextWrap = styled.div`
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: ${(props) => (props.disabled ? "#7A6D6D" : "#251010")};

  ${(props) =>
    props.icon &&
    `
      padding-right: 10px;
    `}
`;

const Answer = ({ text, onChange, selected, type, disabled, icon }) => {
  //const firstWordRegex = /([\w']+)(.*)/;
  //const [, firstWord, rest] = text.match(firstWordRegex);
  let inputStyling;
  if (type === "radio") {
    inputStyling = <RadioButtonStyling />;
  } else {
    inputStyling = (
      <CheckboxStyling>
        <CheckIcon className="icon" />
      </CheckboxStyling>
    );
  }

  let answerIcon;
  switch (icon) {
    case "thumbsup":
      answerIcon = <ThumbsUpIcon />;
      break;
    default:
      answerIcon = "";
      break;
  }

  const handleOnChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <Label>
      <StyledPaper selected={selected} disabled={disabled} icon={icon}>
        <Input
          checked={selected}
          value={text}
          type={type}
          name="answer"
          onChange={handleOnChange}
        />

        {inputStyling}

        <TextWrap icon={icon}>
          <AnswerCopy>{text}</AnswerCopy>
        </TextWrap>

        {icon && answerIcon}
      </StyledPaper>
    </Label>
  );
};

export default Answer;
