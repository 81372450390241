import { KeyboardTabOutlined } from "@material-ui/icons";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { Button } from "@redwigwam/redalerts.elements.button";
import React from "react";
import styled from "styled-components";
import Modal from "../Modal";

const ModalButton = styled(Button)`
  margin: 8px 0;
`;

const ModalButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
`;

const ContinueVisitModal = ({ open, setOpen, handleEnd, handleContinue }) => {
  return (
    <Modal open={open}>
      <ModalButtonWrap>
        <ModalButton
          secondary
          text="End"
          onClick={handleEnd}
          startIcon={<ExitToApp />}
          fullWidth
        />
        <ModalButton
          primary
          text="Continue"
          onClick={handleContinue}
          startIcon={<KeyboardTabOutlined />}
          fullWidth
        />
        <ModalButton
          text="Cancel"
          onClick={() => setOpen(false)}
          startIcon={<ArrowBack />}
          fullWidth
        />
      </ModalButtonWrap>
    </Modal>
  );
};

export default ContinueVisitModal;
