import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectUpdateAvailable,
  setUpdateAvailable,
} from "../../store/settings.slice";
const Bar = styled.div`
  position: fixed;
  bottom: 0;
  background: ${(props) => props.theme.colours.pink};
  color: white;
  width: 100%;
  text-align: center;
  z-index: 1000;
  padding-bottom: 8px;
`;
const UpdateAction = styled.span`
  padding: 0 1em;
  text-decoration: underline;
  cursor: pointer;
`;
const DismissAction = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const UpdateBar = () => {
  const dispatch = useDispatch();
  const isUpdateAvailable = useSelector(selectUpdateAvailable);

  const handleUpdate = async () => {
    // service-worker.js has a click listener on #skip-waiting-button
    dispatch(setUpdateAvailable(false));
  };

  const handleDismiss = () => {
    dispatch(setUpdateAvailable(false));
  };
  if (!isUpdateAvailable) return null;
  return (
    <Bar>
      <p>
        An update is available
        <UpdateAction id="skip-waiting-button" onClick={handleUpdate}>
          Update
        </UpdateAction>
        <DismissAction onClick={handleDismiss}>Dismiss</DismissAction>
      </p>
    </Bar>
  );
};

export default UpdateBar;
