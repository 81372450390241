import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@redwigwam/redalerts.elements.button";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckIcon from "@material-ui/icons/Check";
import {
  selectAutoPromptHelp,
  selectHelpLink,
  selectHelpText,
  selectHelpTitle,
  selectHelpVideoLink,
} from "../../store/visit.slice";
import {
  setModalDismissal,
  selectCurrentQuestionId,
  selectIsSelectedQuestionDismissedModal,
} from "../../store/settings.slice";
import { useDispatch, useSelector } from "react-redux";
import Markdown from "../Markdown";

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-color: #ffffffaa;
  touch-action: none;
  transition: opacity 0.2s;
  top: ${(props) => !props.show && "100%"};
`;

const SwipeHandleWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
`;

const SwipeHandle = styled.div`
  height: 5px;
  width: 100px;
  background-color: #77777777;
  border-radius: 16px;
`;

const DrawerContainer = styled.div`
  background-color: white;
  position: absolute;
  width: 100%;
  height: 90%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
  transition: bottom 0.2s, top 0.2s;
  bottom: ${(props) => (props?.show ? `${props?.deltaY * -1}px` : "-100%")};
`;

const CloseWrap = styled.div`
  position: relative;
  height: 24px;
  margin-bottom: 24px;
`;
const ContentWrap = styled.div`
  overflow-y: auto;
  touch-action: pan-y; /* ensures that background is not scrolled*/
  height: calc(100% - 48px);

  &.show-checkbox {
    height: calc(100% - 88px);
  }
`;

const CloseButton = styled(CloseIcon)`
  color: ${(props) => props?.theme?.colours?.purple};
`;

const HelpTitle = styled.h1`
  color: ${(props) => props?.theme?.colours?.purple};
  font-weight: 600;
  text-align: center;
  font-size: 16px;
`;


const VideoPlayer = styled.video`
  border-radius: 8px;
`;

const CheckboxStyling = styled.span`
  display: block;
  position: relative;
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: white;
  border: 2px solid ${(props) => props?.theme?.colours?.purple};

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: ${(props) => props?.theme?.colours?.white};
  }
`;

const Input = styled.input.attrs((props) => ({
  type: props.type,
}))`
  display: none;

  &:checked ~ ${CheckboxStyling} {
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: ${(props) => props?.theme?.colours?.purple};
    }
  }
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const MoreDetail = styled(Button)`
  width: calc(100% - 12px);
`;

const HelpDrawer = ({ show, setShow }) => {
  const [deltaY, setDeltaY] = useState(0);

  const containerRef = useRef();

  useEffect(() => {
    if (show) {
      setDeltaY(0);
    }
  }, [show]);

  const handleSwiping = (e) => {
    if (containerRef?.current?.getBoundingClientRect) {
      const { dir, deltaY } = e;

      const dragDownToCloseDistance =
        containerRef.current.getBoundingClientRect().height * 0.25;

      if (dir === "Down" && deltaY > 0) {
        if (deltaY > dragDownToCloseDistance) {
          setShow(false);
        } else {
          setDeltaY(deltaY);
        }
      } else {
        setDeltaY(0);
      }
    }
  };

  const handleSwiped = () => {
    setDeltaY(0);
  };

  const swipeHandlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwiped: handleSwiped,
    trackMouse: true,
  });

  const autoPrompt = useSelector(selectAutoPromptHelp);
  const link = useSelector(selectHelpLink);
  const text = useSelector(selectHelpText);
  const title = useSelector(selectHelpTitle);
  const videoLink = useSelector(selectHelpVideoLink);
  const currentQuestionId = useSelector(selectCurrentQuestionId);
  const isModalDismissed = useSelector(selectIsSelectedQuestionDismissedModal);

  const [inputChecked, setInputChecked] = useState(false);

  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setInputChecked(e.target.checked);
  };

  const closeModal = () => {
    setShow(false);

    dispatch(setModalDismissal(currentQuestionId));
  };

  return (
    <Overlay show={show}>
      <DrawerContainer deltaY={deltaY} ref={containerRef} show={show}>
        <CloseWrap>
          <CloseButton onClick={closeModal} />
          <SwipeHandleWrap {...swipeHandlers}>
            <SwipeHandle />
          </SwipeHandleWrap>
        </CloseWrap>
        <ContentWrap
          className={autoPrompt && !isModalDismissed && "show-checkbox"}
        >
          {videoLink && (
            <VideoPlayer width="100%" height="240" controls>
              <source src={videoLink} type="video/mp4" />
              Your browser does not support the video tag.
            </VideoPlayer>
          )}

          {title && <HelpTitle>{title}</HelpTitle>}

          {text && <Markdown text={text} />}

          {link && (
            <MoreDetail
              secondary
              text="More detail"
              startIcon={<InfoOutlinedIcon />}
            />
          )}
        </ContentWrap>
        {autoPrompt && !isModalDismissed && (
          <Label>
            <Input
              checked={inputChecked}
              value="Don't show automatically"
              type="checkbox"
              onChange={handleOnChange}
            />

            <CheckboxStyling>
              <CheckIcon className="icon" />
            </CheckboxStyling>

            <span>Don't show automatically</span>
          </Label>
        )}
      </DrawerContainer>
    </Overlay>
  );
};

export default HelpDrawer;
