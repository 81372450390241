import { Button } from "@redwigwam/redalerts.elements.button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPermissionModalShown,
  setPermissionModalShown,
} from "../../store/settings.slice";
import Modal from "../Modal";

import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import styled from "styled-components";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ExitToApp from "@material-ui/icons/ExitToApp";
import authService from "../../services/auth";
import {
  checkCameraPermission,
  checkLocationPermission,
} from "../../services/permissions";

const ErrorIcon = styled(ReportProblemOutlinedIcon)`
  width: 48px !important;
  height: 48px !important;
  color: ${(props) => props?.theme?.colours?.red};
  margin-bottom: 16px;
`;

const NoCameraWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    width: 100%;
    margin: 0 0 16px 0;
    font-size: 14px;
  }
`;

const GenericWrap = styled(NoCameraWrap)`
  text-align: left;

  h1 {
    text-align: center;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: ${(props) => props?.theme?.colours?.purple};

    margin-bottom: 16px;
  }
`;

const Permissions = () => {
  const [open, setOpen] = useState(false);
  const permissionModalShown = useSelector(selectPermissionModalShown);
  const [cameras, setCameras] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!permissionModalShown) {
      getCameraDevices();

      setOpen(true);
    }
  }, [permissionModalShown]);

  const handleLogout = () => {
    authService.logOut();
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setPermissionModalShown(true));
    checkCameraPermission();
    checkLocationPermission();
  };

  const getCameraDevices = () => {
    navigator.mediaDevices.enumerateDevices().then((data) => {
      const videoDevices = data?.filter(
        (device) => device?.kind === "videoinput"
      );
      setCameras(videoDevices);
    });
  };

  return (
    <Modal open={open}>
      {cameras?.length === 0 ? (
        <NoCameraWrap>
          <ErrorIcon />
          <p>Unfortunately no cameras were detected on this device. </p>
          <p>
            Tasks often require photographic evidence, so please try again on
            another device
          </p>
          <Button
            text="Logout and quit"
            primary
            startIcon={<ExitToApp />}
            onClick={handleLogout}
          />
        </NoCameraWrap>
      ) : (
        <GenericWrap>
          <h1>Welcome to Redwigwam tasks</h1>
          <p>Before we begin, our app requires a couple of permissions.</p>
          <p>
            After closing this message, your device will prompt for both camera
            and location access. This is needed as all tasks require some
            photographic and geographic evidence.
          </p>

          <Button
            primary
            onClick={handleClose}
            text="OK Let's go"
            fullWidth
            endIcon={<ArrowForward />}
          />
        </GenericWrap>
      )}
    </Modal>
  );
};

export default Permissions;
