import { Button } from "@redwigwam/redalerts.elements.button";
import React from "react";
import styled from "styled-components";
import Modal from "../Modal";

const ModalButton = styled(Button)`
  margin-bottom: 16px;
`;

const Question = styled.p`
  text-align: center;
`;

const CancelVisitModal = ({ open, setOpen, handleCancel }) => {
  return (
    <Modal open={open}>
      <Question>
        Cancel working on this task and allow it to be picked up by another
        worker?
      </Question>
      <ModalButton
        text="No"
        secondary
        onClick={() => setOpen(false)}
        fullWidth
      />
      <ModalButton text="Yes" primary onClick={handleCancel} fullWidth />
    </Modal>
  );
};

export default CancelVisitModal;
