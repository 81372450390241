import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectFullName, selectProfileUrl } from "../../store/user.slice";
import { selectCurrentVisitData } from "../../store/visit.slice";

const Container = styled.div`
  padding-top: 80px;
  padding-bottom: 50px;
  background: #a1348c;
  border-radius: 8px;
  color: white;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin: 60px auto 0 auto;
  filter: drop-shadow(0px 2px 8px rgba(37, 16, 16, 0.2));
`;

const ProfilePicture = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: #a1348c;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  filter: drop-shadow(0px 2px 8px rgba(37, 16, 16, 0.2));

  background-image: url(${(props) => props.$src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const Name = styled.p`
  font-family: Montserrat;
  font-size: 24px;s
  font-weight: 500;
  line-height: 29px;
  margin: 0;
`;

const HR = styled.hr`
  opacity: 0.2;
  width: 80%;
`;
const Title = styled.p`
  font-family: Arimo;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  opacity: 50%;
  margin-bottom: 4px;
`;
const Body = styled.p`
  font-family: Arimo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  text-align: center;
`;
const CompanyLogo = styled.div`
  height: 72px;
  width: 72px;
  border-radius: 8px;
  border: 2px solid white;

  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  background: #a1348c;
  filter: drop-shadow(0px 2px 8px rgba(37, 16, 16, 0.2));

  background-image: url(${(props) => props.$src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const IDBadge = () => {
  const name = useSelector(selectFullName);
  const visit = useSelector(selectCurrentVisitData);
  const profileUrl = useSelector(selectProfileUrl);
  const {
    plannedStartDate,
    plannedEndDate,
    location: { name: location = "Unknown" } = {},
    hirerName = "Unknown",
    hirerLogoUrl,
  } = visit;

  const date = `${new Date(plannedStartDate).toLocaleDateString(
    "en-gb"
  )} - ${new Date(plannedEndDate).toLocaleDateString("en-gb")} `;

  return (
    <Container>
      <ProfilePicture $src={profileUrl} />
      <Name>{name}</Name>
      <HR />
      <Title>Authorised to be working on behalf of</Title>
      <Body>{hirerName}</Body>

      <Title>At the following location</Title>
      <Body>{location}</Body>

      <Title>Between the following dates</Title>
      <Body>{date}</Body>
      <CompanyLogo $src={hirerLogoUrl} />
    </Container>
  );
};

export default IDBadge;
