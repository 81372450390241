export const getSquareScreenshotFromVideo = (
  canvasRef,
  webcamRef,
  canvasSize
) => {
  const video = webcamRef.current;

  const { width: clientWidth, height: clientHeight } =
    video.getBoundingClientRect();
  const clientAspectRatio = clientWidth / clientHeight;

  const {
    width: videoWidth,
    height: videoHeight,
    aspectRatio: videoAspectRatio,
  } = video.srcObject.getVideoTracks()[0].getSettings();

  let aspectRatio = videoAspectRatio;

  // sometimes the aspect ratio in the video settings is simply wrong.
  // verify against the video elements size, and adjust if nessesary
  if (
    (clientAspectRatio > 1 && videoAspectRatio < 1) ||
    (clientAspectRatio < 1 && videoAspectRatio > 1)
  ) {
    aspectRatio = videoHeight / videoWidth;
  }

  const canvasContext = canvasRef.current.getContext("2d");

  const sourceX = aspectRatio > 1 ? (videoWidth - videoHeight) / 2 : 0;
  const sourceY = aspectRatio > 1 ? 0 : (videoHeight - videoWidth) / 2;
  const sourceWidth = aspectRatio > 1 ? videoHeight : videoWidth;
  const sourceHeight = aspectRatio > 1 ? videoHeight : videoWidth;
  const canvasX = 0;
  const canvasY = 0;
  const canvasHeight = canvasSize;
  const canvasWidth = canvasSize;

  // https://dev.to/mathewthe2/using-html-canvas-to-snip-screenshots-of-your-video-4c4h
  canvasContext.drawImage(
    video,
    sourceX,
    sourceY,
    sourceWidth,
    sourceHeight,
    canvasX,
    canvasY,
    canvasWidth,
    canvasHeight
  );

  // 70% quality
  return canvasRef.current.toDataURL("image/jpeg", 0.7);
};
