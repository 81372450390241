import { Button } from "@redwigwam/redalerts.elements.button";
import React from "react";
import styled from "styled-components";
import Modal from "../Modal";

const ModalButton = styled(Button)`
  margin-bottom: 16px;
`;

const ModalButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
`;

const ErrorList = styled.pre`
  max-height: 200px;
  overflow-y: auto;
  touch-action: pan-x;
`;

const ErrorModal = ({ open, setOpen, errors, handleRemove }) => {
  return (
    <Modal open={open}>
      <p>Error Log</p>
      <ErrorList>{JSON.stringify(errors, null, 2)}</ErrorList>
      <p>
        For assistance please reach out via&nbsp;
        <a
          href="https://www.redwigwam.com/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          live chat
        </a>
      </p>
      <ModalButtonWrap>
        <ModalButton text="Close" onClick={() => setOpen(false)} />
        <ModalButton secondary text="Remove" onClick={handleRemove} />
      </ModalButtonWrap>
    </Modal>
  );
};

export default ErrorModal;
