import React from "react";
import { useSelector } from "react-redux";
import LayoutBottom from "../components/Layout/LayoutBottom";
import LayoutTop from "../components/Layout/LayoutTop";
import Menu from "../components/Menu/Menu";
import Meta from "../components/Meta";
import VisitDisplay from "../components/Visit/VisitDisplay";
import { isVisitFinished } from "../services/visit";
import { selectVisitsInCurrentVisitLocation } from "../store/visit.slice";

const FollowOnVisits = () => {
  const visitsInCurrentLocation = useSelector(
    selectVisitsInCurrentVisitLocation
  );

  const unfinishedVisits = visitsInCurrentLocation.filter(
    (visit) => !isVisitFinished(visit)
  );

  return (
    <>
      <Meta title="Follow on" />
      <LayoutTop>
        <Menu />
      </LayoutTop>
      <LayoutBottom>
        {unfinishedVisits.map((visit) => (
          <VisitDisplay key={visit?.data?.id} visit={visit} />
        ))}
      </LayoutBottom>
    </>
  );
};

export default FollowOnVisits;
