import React, { useState } from "react";
import styled from "styled-components";
import LayoutBottom from "../components/Layout/LayoutBottom";
import LayoutTop from "../components/Layout/LayoutTop";
import Menu from "../components/Menu/Menu";
import Meta from "../components/Meta";
import { getLatLng, getPosition } from "../services/geolocation";

const Details = styled.details`
  margin: 24px 0;
`;

const Debug = () => {
  const [geoLocationOutput, setGeolocationOutput] = useState();
  const [geoPermission, setGeoPermissions] = useState();

  const [cameraOutput, setCameraOutput] = useState();
  const [cameraPermission, setCameraPermission] = useState();

  // const [storageQuota, setStorageQuota] = useState({ total: null, used: null });

  const defaultGeoLocation = () => {
    getLatLng()
      .then((data) => setGeolocationOutput(data))
      .catch((e) => setGeolocationOutput(e))
      .finally(() => {
        getPermission("geolocation", setGeoPermissions);
      });
  };

  const justGeolocation = () => {
    getPosition()
      .then((data) => {
        setGeolocationOutput(cloneAsObject(data));
      })
      .catch((e) => {
        setGeolocationOutput(cloneAsObject(e));
      })
      .finally(() => {
        getPermission("geolocation", setGeoPermissions);
      });
  };

  function cloneAsObject(obj) {
    if (obj === null || !(obj instanceof Object)) {
      return obj;
    }
    var temp = obj instanceof Array ? [] : {};
    for (var key in obj) {
      temp[key] = cloneAsObject(obj[key]);
    }
    return temp;
  }

  const getPermission = (name, callback) => {
    navigator.permissions
      .query({ name })
      .then((data) => {
        callback(cloneAsObject(data));
      })
      .catch((data) => {
        callback(cloneAsObject(data));
      });
  };

  const getCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((data) => setCameraOutput(cloneAsObject(data)))
      .catch((data) => setCameraOutput(cloneAsObject(data)))
      .finally(() => {
        getPermission("camera", setCameraPermission);
      });
  };
  // useEffect(() => {
  //   printIndexDBSizes().then((data) => {
  //     const totalUsed = Object.values(data).reduce(
  //       (bytes, acc) => acc + bytes,
  //       0
  //     );
  //     setStorageQuota({
  //       total: prettyBytes(storage?._config?.size),
  //       used: prettyBytes(totalUsed),
  //     });
  //   });
  // }, []);

  return (
    <>
      <Meta title="Debug" />
      <LayoutTop>
        <Menu backButtonPath="/" />
      </LayoutTop>
      <LayoutBottom>
        <Details>
          <summary>Geolocation</summary>

          <div>
            <button onClick={defaultGeoLocation}>Default Behaviour</button>
            <button onClick={justGeolocation}>Just location api</button>
          </div>
          <div>
            <pre>{JSON.stringify(geoLocationOutput, null, 2)}</pre>
            <pre>{JSON.stringify(geoPermission, null, 2)}</pre>
          </div>
        </Details>

        <Details>
          <summary>Camera</summary>

          <div>
            <button onClick={getCamera}>Get Camera Permission</button>
          </div>
          <div>
            <pre>{JSON.stringify(cameraOutput, null, 2)}</pre>
            <pre>{JSON.stringify(cameraPermission, null, 2)}</pre>
          </div>
        </Details>

        {/* <Details>
          <summary>Storage</summary>

          <pre>{JSON.stringify(storage._config, null, 2)}</pre>
          <pre>
            Available Storage: {storageQuota.used}/{storageQuota.total}
          </pre>
        </Details> */}
      </LayoutBottom>
    </>
  );
};

export default Debug;
