import { createSelector, createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { authenticated: false },
  reducers: {
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
  },
});

export const { setAuthenticated, setUserData } = authSlice.actions;

const selectSlice = (state) => state?.[authSlice.name];

export const selectAuthenticated = createSelector(
  selectSlice,
  (slice) => slice.authenticated || false
);

export default authSlice;
