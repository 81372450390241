export const checkCameraPermission = () => {
  return new Promise((resolve, reject) => {
    try {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
          resolve();
        })
        .catch((e) => reject(e));
    } catch (e) {
      reject(e);
    }
  });
};

export const checkLocationPermission = () => {
  return new Promise((resolve, reject) => {
    try {
      navigator.geolocation.getCurrentPosition(
        (d) => resolve(d),
        (e) => reject(e)
      );
    } catch (e) {
      reject(e);
    }
  });
};

export const checkAllPermissions = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const promises = [checkCameraPermission(), checkLocationPermission()];

      const results = await Promise.allSettled(promises);
      console.log(results);

      if (results.every((result) => result.status === "fulfilled")) {
        resolve();
      }
      reject();
    } catch (e) {
      reject();
    }
  });
};

export const checkPermissionState = (name) => {
  return new Promise((resolve, reject) => {
    try {
      navigator.permissions
        .query({ name })
        .then((data) => resolve(data?.state))
        .catch((e) => reject(e));
    } catch (e) {
      reject(e);
    }
  });
};
