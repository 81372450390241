import { Button } from "@redwigwam/redalerts.elements.button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../Button/BackButton";
import { ProgressBar } from "@redwigwam/redalerts.elements.progress-bar";
import styled from "styled-components";

import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import MenuDrawer from "../Menu/MenuDrawer";

import { LoggedInName } from "../../store/user.slice";
import {
  selectCategoryProgress,
  selectCurrentProdName,
  selectCurrentSKU,
  selectCurrentStore,
  selectCurrentVisitErrors,
  selectIsCurrentCategoryExcluded,
  selectVisitType,
} from "../../store/visit.slice";
import Modal from "../Modal";
import { useHistory } from "react-router";
import {
  setCurrentCategory,
  setCurrentQuestionId,
  setCurrentVisitId,
} from "../../store/settings.slice";

const Heading = styled.div`
  color: white;
  font-size: 14px;
  margin-bottom: 4px;
`;

const VisitHeading = styled.h1`
  color: white;
`;

const ProductName = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  font-size: 12px;
  overflow-y: scroll;
  white-space: nowrap;
  width: 185px;
`;

const ProductSKU = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  margin-top: 2px;
`;

const HeadingContainer = styled.div`
  text-align: center;
  margin-top: 14px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LogoutButton = styled(Button)`
  color: white;
  margin: 0;
`;

const VisitType = styled.div`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
`;

const HomeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HelloText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 14px;
`;

const Menu = ({ home, backButtonPath }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const currStore = useSelector(selectCurrentStore);
  const currProdName = useSelector(selectCurrentProdName);
  const currSKU = useSelector(selectCurrentSKU);
  const visitType = useSelector(selectVisitType);
  const categoryProgress = useSelector(selectCategoryProgress);
  const hideProgressBar = useSelector(selectIsCurrentCategoryExcluded);
  const name = useSelector(LoggedInName);
  const dispatch = useDispatch();

  const history = useHistory();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const currentVisitErrors = useSelector(selectCurrentVisitErrors);
  // show visit error modal
  useEffect(() => {
    if (currentVisitErrors.length > 0) {
      setShowErrorModal(true);
    }
  }, [currentVisitErrors]);

  const handleError = () => {
    dispatch(setCurrentVisitId());
    dispatch(setCurrentQuestionId());
    dispatch(setCurrentCategory());
    setShowErrorModal(false);
    history.push("/");
  };

  return (
    <>
      <Modal open={showErrorModal}>
        <p>
          An error has occured in this task, for more details, refer to task on
          home screen
        </p>
        <Button text="Return to home" primary onClick={handleError} />
      </Modal>
      {home ? (
        <>
          <HomeContainer>
            <HelloText>Hello {name}</HelloText>
            <LogoutButton
              onClick={() => setMenuOpen(true)}
              startIcon={<AccountCircleOutlinedIcon />}
            />
          </HomeContainer>
          <HeadingContainer>
            <VisitHeading>My available tasks</VisitHeading>         
          </HeadingContainer>
        </>
      ) : (
        <>
          <Container>
            <BackButton path={backButtonPath} />
            <HeadingContainer>
              <Heading>{currStore}</Heading>
              {visitType === "Distribution" ? (
                <>
                  <ProductName>{currProdName}</ProductName>
                  <ProductSKU>SKU: {currSKU}</ProductSKU>
                </>
              ) : (
                <VisitType>{visitType}</VisitType>
              )}
            </HeadingContainer>
            <LogoutButton
              onClick={() => setMenuOpen(true)}
              startIcon={<AccountCircleOutlinedIcon />}
            />
          </Container>
          {!hideProgressBar && <ProgressBar progress={categoryProgress} />}
        </>
      )}
      <MenuDrawer show={menuOpen} setShow={setMenuOpen} />
    </>
  );
};

export default Menu;
