import React from "react";
import styled from "styled-components";

export const height = "130px";

const Background = styled.div`
  background-color: ${(props) => props?.theme?.colours?.purple};
  height: ${height};
  padding: 14px 14px 0 14px;
`;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  position: relative;
`;

const LayoutTop = ({ children }) => {
  

  return (
    <Background id="layout-top">
      <Container>
        {children}
      </Container>
    </Background>
  );
};

export default LayoutTop;
