import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "styled-components";

// const exampleText = `## Headings
// # h1 Heading 
// ## h2 Heading
// ### h3 Heading
// #### h4 Heading
// ##### h5 Heading
// ###### h6 Heading

// ## Emphasis

// **This is bold text**
// __This is bold text__
// *This is italic text*
// _This is italic text_
// ~~Strikethrough~~

// ## Lists

// Unordered

// + Create a list by starting a line with \` +
//   \`, \` -
//   \`, or \` *
//     \`
// + Sub-lists are made by indenting 2 spaces:
//   - Marker character change forces new list start:
//     * Ac tristique libero volutpat at
//     + Facilisis in pretium nisl aliquet
//     - Nulla volutpat aliquam velit
// + Very easy!

// Ordered

// 1. Lorem ipsum dolor sit amet
// 2. Consectetur adipiscing elit
// 3. Integer molestie lorem at massa


// 1. You can use sequential numbers...
// 1. ...or keep all the numbers as 1.

// ## Links

// [link text](http://dev.nodeca.com)

// [link with title](http://nodeca.github.io/pica/demo/ "title text!")

// ## Tables

// | Tables   |      Are      |  Cool |
// |----------|:-------------:|------:|
// | col 1 is |  left-aligned | $1600 |
// | col 2 is |    centered   |   $12 |
// | col 3 is | right-aligned |    $1 |

// ## Image

// ![Stormtroopocat](https://octodex.github.com/images/stormtroopocat.jpg "The Stormtroopocat")

// `;

const StyledMarkdown = styled(ReactMarkdown)`
  max-width: 600px;
  margin: 0 auto;
  img {
    height: 250px;
    margin: 0 auto;
    display: block;
  }

  table {
    display: block;
    width: 100%;
    overflow: auto;
    border-spacing: 0;
    border-collapse: collapse;

    th {
      font-weight: 600;
    }
    th,
    td {
      padding: 6px 13px;
      border: 1px solid #dfe2e5;
    }
    tr {
      background-color: #fff;
      border-top: 1px solid #c6cbd1;

      &:nth-child(2n) {
        background-color: #f6f8fa;
      }
    }
  }
`;
const Markdown = ({ text }) => (
  <StyledMarkdown children={text} remarkPlugins={[remarkGfm]} />
);

export default Markdown;
