import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectAuthenticated } from "../../store/auth.slice";
import Permissions from "../Permissions";

const AuthRoute = (props) => {
  const isAuthenticated = useSelector(selectAuthenticated);
  return isAuthenticated ? (
    <>
      <Permissions />
      <Route {...props} />{" "}
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default AuthRoute;
