import React from "react";
import { Button } from "@redwigwam/redalerts.elements.button";

const UnexpectedQuestion = ({ handleChange }) => {
  return (
    <Button
      text="Unexpected Question Type"
      onClick={() => handleChange("Unexpected")}
    />
  );
};

export default UnexpectedQuestion;
