import axios from "axios";
import localforage from "localforage";

export class AuthService {
  constructor(name = "authStorage") {
    this.error = null;
    this.listener = null;
    this.refreshTimeout = null;
    this.store = localforage.createInstance({ name });
  }

  async getRefreshToken() {
    return await this.store.getItem("refreshToken");
  }

  async getAccessToken() {
    const token = await this.store.getItem("accessToken");
    const expiry = await this.store.getItem("accessTokenExpiryTime");

    return { token, expiry };
  }

  setRefreshToken(token) {
    if (token) {
      this.store.setItem("refreshToken", token);
    } else {
      this.store.removeItem("refreshToken");
    }
  }

  setAccessToken(token, expiry) {
    if (token) {
      const expiryTime = new Date().getTime() + expiry * 1000;

      this.store.setItem("accessToken", token);
      this.store.setItem("accessTokenExpiryTime", expiryTime);
    } else {
      this.store.removeItem("accessToken");
      this.store.removeItem("accessTokenExpiryTime");
    }

    this.listener(token, expiry);
  }

  registerListener(func) {
    this.listener = func;
  }

  async silentLogIn() {
    return new Promise((resolve, reject) => {
      try {
        // grab token from storage
        this.getRefreshToken().then((refreshToken) => {
          if (!refreshToken) {
            this.getAccessToken().then((token) =>
              reject({ token, error: "No refresh token" })
            );
          }

          // build request as per spec
          //https://datatracker.ietf.org/doc/html/rfc6749#section-6
          const params = new URLSearchParams();
          params.append("grant_type", "refresh_token");
          params.append("refresh_token", refreshToken);
          params.append("client_id", OIDC_CONSTS.clientId);
          params.append("scope", OIDC_CONSTS.scope);
          params.append("response_type", "id_token");

          const config = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          };

          // send request

          axios
            .post("/connect/token", params, config)
            .then((response) => {
              const { data: { access_token, expires_in, refresh_token } = {} } =
                response;
              if (!access_token || !expires_in || !refresh_token) {
                this.getAccessToken().then((token) =>
                  reject({ token, error: "Unexpected response" })
                );
              }

              // store tokens in store
              this.setAccessToken(access_token, expires_in);
              this.setRefreshToken(refresh_token);

              this.registerTokenRefresh(expires_in);

              this.getAccessToken().then((token) => resolve({ token }));
            })
            .catch((e) => {
              this.getAccessToken().then((token) =>
                reject({ token, error: e.message })
              );
            });
        });
      } catch (e) {
        this.getAccessToken().then((token) =>
          reject({ token, error: e.message })
        );
      }
    });
  }

  registerTokenRefresh(expiry) {
    const nextSilentRefresh = new Date().getTime() + expiry * 1000;
    console.log("Next silent refresh", new Date(nextSilentRefresh));
    this.refreshTimeout = setTimeout(() => {
      this.silentLogIn();
    }, expiry * 1000);
  }

  logOut() {
    // remove refresh token and reload page
    this.setAccessToken();
    this.setRefreshToken();
    clearTimeout(this.refreshTimeout);
  }

  async logIn(username, password) {
    // build request as per spec
    // https://datatracker.ietf.org/doc/html/rfc6749#section-4.4.2
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    params.append("grant_type", "password");
    params.append("scope", OIDC_CONSTS.scope);
    params.append("client_id", OIDC_CONSTS.clientId);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const { data: { access_token, expires_in, refresh_token } = {} } =
      await axios.post("/connect/token", params, config);

    if (!access_token || !expires_in || !refresh_token) {
      throw new Error("Unexpected token response");
    }

    // store response in memory
    this.setAccessToken(access_token, expires_in);

    // store token in store
    this.setRefreshToken(refresh_token);

    this.registerTokenRefresh();
  }
}

const authService = new AuthService();

export default authService;

export const OIDC_CONSTS = {
  scope: "RwwWorkerAppPWAAPI openid profile offline_access", // offline access adds refresh token to responses
  clientId: "RwwWorkerAppPWA",
};
