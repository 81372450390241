import {
  combineReducers,
  configureStore,
  createSelector,
} from "@reduxjs/toolkit";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import localforage from "localforage";

import visit from "./visit.slice";
import authSlice from "./auth.slice";
import settingsSlice from "./settings.slice";
import userSlice from "./user.slice";

export const storage = localforage.createInstance({ name: "redux-persist" });

const rootReducer = combineReducers({
  [settingsSlice.name]: settingsSlice.reducer,
  [visit.name]: visit.reducer,
  [authSlice.name]: authSlice.reducer,
  [userSlice.name]: userSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const selectPersist = (state) => state._persist;
export const selectRehydrated = createSelector(
  selectPersist,
  (persist) => persist.rehydrated
);
