import React, { useState } from "react";

import Answer from "../Answer";

const MultipleChoiceMultipleAnswers = ({
  choices,
  answer = [],
  handleChange,
}) => {
  const [answerGroup, setAnswerGroup] = useState();
  const handleMultiSelect = (checked, choice) => {
    const alreadySelectedIndex = answer?.findIndex?.(
      (answer) => JSON.stringify(choice) === JSON.stringify(answer)
    );

    const removeAnswer = () => {
      // remove from array
      const updatedAnswer = [...answer];
      updatedAnswer.splice(alreadySelectedIndex, 1);

      if (updatedAnswer.length === 0) {
        setAnswerGroup();
      }
      handleChange(updatedAnswer);
    };

    const addAnswer = () => {
      setAnswerGroup(choice.AnswerGroup);
      handleChange((prev) => [...(prev || []), choice]);
    };

    const resetToNewGroup = () => {
      setAnswerGroup(choice.AnswerGroup);
      handleChange([choice]);
    };

    if (!checked) {
      removeAnswer();
    } else {
      if (
        answer?.length > 0 &&
        parseInt(choice.AnswerGroup) !== parseInt(answerGroup)
      ) {
        resetToNewGroup();
      } else {
        if (alreadySelectedIndex > -1) {
          removeAnswer();
        } else {
          addAnswer();
        }
      }
    }
  };

  return choices.map((choice) => {
    const { Choice, AnswerGroup, AnswerIcon } = choice;

    const isSelected = Boolean(
      answer?.find?.(
        (answer) => JSON.stringify(choice) === JSON.stringify(answer)
      )
    );
    return (
      <Answer
        key={Choice}
        text={Choice}
        onChange={(checked) => handleMultiSelect(checked, choice)}
        selected={isSelected}
        disabled={
          answer?.length > 0 && parseInt(AnswerGroup) !== parseInt(answerGroup)
        }
        type="checkbox"
        icon={AnswerIcon}
      />
    );
  });
};

export default MultipleChoiceMultipleAnswers;
