import ArrowBack from "@material-ui/icons/ArrowBack";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { Button } from "@redwigwam/redalerts.elements.button";
import React from "react";
import styled from "styled-components";
import Modal from "../Modal";

const ModalButton = styled(Button)`
  margin-bottom: 16px;
`;

const ModalQuestion = styled.p`
  text-align: center;
`;

const ModalButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
`;

const ResetVisitModal = ({ open, setOpen, handleReset }) => {
  return (
    <Modal open={open}>
      <ModalQuestion>
        All previous submitted answers for this task will be deleted and you
        will have to start over. Are you sure?
      </ModalQuestion>

      <ModalButtonWrap>
        <ModalButton
          secondary
          text="End"
          onClick={handleReset}
          startIcon={<ExitToApp />}
          fullWidth
        />
        <ModalButton
          text="Cancel"
          onClick={() => setOpen(false)}
          startIcon={<ArrowBack />}
          fullWidth
        />
      </ModalButtonWrap>
    </Modal>
  );
};

export default ResetVisitModal;
