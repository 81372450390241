import React from "react";
import styled from "styled-components";
import splashImage from "../images/splashscreen.jpg";
import logo from "../images/login-logo.png";

const SplashBackground = styled.div`
  background-image: url(${splashImage});
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.div`
  width: 180px;
  height: 180px;
  background-color: white;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  padding: 36px;
  width: 100%;
`;

const LoadingSplash = () => {
  return (
    <SplashBackground>
      <Circle>
        <Logo src={logo} />
      </Circle>
    </SplashBackground>
  );
};

export default LoadingSplash;
