import { Button } from "@redwigwam/redalerts.elements.button";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

const StyledButton = styled(Button)`
  color: white;
  margin: 0;

  :hover {
    color: lightgrey;
  }
`;

const BackButton = ({ path }) => {
  const history = useHistory();
  const handleClick = () => {
    if (path) {
      history.push(path);
    } else {
      history.goBack();
    }
  };
  return (
    <StyledButton
      onClick={handleClick}
      endIcon={
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 15L10.8812 14.1188L7.39375 10.625H15V9.375H7.39375L10.8812 5.88125L10 5L5 10L10 15Z"
            fill="white"
          />
          <path
            d="M10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20ZM10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2Z"
            fill="white"
          />
        </svg>
      }
    />
  );
};

export default BackButton;
