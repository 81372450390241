import React from "react";
import Answer from "../Answer";

const MoreVisits = ({ choices, answer, handleChange }) => {
  return choices.map((choice) => {
    const { Choice } = choice;

    const isSelected = JSON.stringify(choice) === JSON.stringify(answer);

    return (
      <Answer
        key={Choice}
        text={Choice}
        onChange={() => handleChange(choice)}
        selected={isSelected}
        type="radio"
      />
    );
  });
};

export default MoreVisits;
