import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../App";
import authService from "../services/auth";
import { TextInput } from "@redwigwam/redalerts.fields.text";
import { Button } from "@redwigwam/redalerts.elements.button";
import styled from "styled-components";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Meta from "../components/Meta";

import LoginLogo from "../images/login-logo.png";
import dataService from "../services/data";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, setUserData } from "../store/user.slice";
import { clearVisits } from "../store/visit.slice";
import { clearSettings } from "../store/settings.slice";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props?.theme?.colours?.purple};
  overflow: auto;
  position: relative;
`;

const LoginContainer = styled.div`
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  max-width: ${(props) => props?.theme?.breakpoints?.values?.sm}px;
  width: 100%;
  height: calc(100% - 48px);
  background-color: white;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 0 24px;
`;

const Logo = styled.img`
  width: 70px;
  height: 48px;
  margin-bottom: 24px;
  margin-top: 48px;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const EmailField = styled(TextInput)`
  margin: 0 0 24px;
`;

const PasswordField = styled(TextInput)`
  margin: 0 0 24px;
`;

const WelcomeText = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: ${(props) => props.theme.colours.purple};
  margin: 0 0 8px 0;
`;

const InstructionText = styled.p`
  margin: 0 0 32px 0;
`;

const ForgottenLink = styled.a`
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: ${(props) => props.theme.colours.purple};
  text-align: center;
  margin-top: 24px;
`;

const ErrorWrapper = styled.div`
  margin-bottom: 10px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
`;

const Login = () => {
  const [formInput, setFormInput] = useState({ email: "", password: "" });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useContext(AuthContext);
  const currentUserData = useSelector(selectUserData);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated, history]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await authService.logIn(formInput.email, formInput.password);
      const userDataResponse = await dataService.getUserData();
      dispatch(setUserData(userDataResponse?.data ?? {}));

      if (
        userDataResponse?.data?.emailAddress !== currentUserData?.emailAddress
      ) {
        // different user, clear old visits
        dispatch(clearVisits());
        dispatch(clearSettings());
      }

      setLoading(false);
      history.push("/");
    } catch (e) {
      console.error(e);
      setError(e.message);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormInput((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Background>
      <Meta title="Login" />

      <LoginContainer>
        <Logo src={LoginLogo} alt="Redwigwam Logo" />
        <WelcomeText>Welcome back</WelcomeText>
        <InstructionText>Enter your details below to continue</InstructionText>
        <Form onSubmit={handleLogin}>
          {error &&
            <ErrorWrapper>
              <ErrorMessage>The email and password you entered did not match our records. Please try again.</ErrorMessage>
            </ErrorWrapper>
          }

          <EmailField
            type="email"
            name="email"
            label="Email Address"
            autoComplete="email"
            icon={<EmailOutlinedIcon />}
            value={formInput.email}
            onChange={handleInputChange}
          />

          <PasswordField
            type="password"
            name="password"
            label="Password"
            autoComplete="password"
            icon={<LockOutlinedIcon />}
            value={formInput.password}
            onChange={handleInputChange}
          />

          <Button
            primary
            type="submit"
            text="Login"
            disabled={loading}
            endIcon={<ArrowForwardIcon />}
            onClick={() => {}}
          />
          <ForgottenLink
            href="https://portal.redwigwam.com/workers/forgot-password"
            target="_blank"
          >
            Forgotten details?
          </ForgottenLink>
        </Form>
      </LoginContainer>
    </Background>
  );
};
export default Login;
