import axiosInstance from "./axios";

const VISIT_PATH = "/Visits";

const REPLACEMENTS = {
  visitId: ":VisitId:",
  questionId: ":QuestionId:",
};

const API_ENDPOINTS = {
  get: {
    visits: `${VISIT_PATH}/json`,
    me: "/me",
    info: "/info",
  },
  post: {
    startVisit: `${VISIT_PATH}/${REPLACEMENTS.visitId}/startvisit`,
    endVisit: `${VISIT_PATH}/${REPLACEMENTS.visitId}/endvisit`,
    sealVisit: `${VISIT_PATH}/${REPLACEMENTS.visitId}/sealvisit`,
    cancelVisit: `${VISIT_PATH}/${REPLACEMENTS.visitId}/cancelvisit`,
    textResponse: `${VISIT_PATH}/${REPLACEMENTS.visitId}/visitquestions/${REPLACEMENTS.questionId}/submittextresponse`,
    photoResponse: `${VISIT_PATH}/${REPLACEMENTS.visitId}/visitquestions/${REPLACEMENTS.questionId}/submitphotoresponse`,
    choiceResponse: `${VISIT_PATH}/${REPLACEMENTS.visitId}/visitquestions/${REPLACEMENTS.questionId}/submitchoiceresponse`,
  },
};

class DataService {
  apiResponse(response, error) {
    return { response, error };
  }

  getEndpoint(template, replacements) {
    let output = template;
    for (let i = 0; i < replacements.length; i++) {
      const [target, replacement] = replacements[i];
      const regex = new RegExp(`${target}`, "g");
      output = output.replace(regex, replacement);
    }

    return output;
  }

  getUserData() {
    return axiosInstance.get(API_ENDPOINTS.get.me);
  }

  getInfo() {
    return axiosInstance.get(API_ENDPOINTS.get.info);
  }

  getVisits() {
    return axiosInstance.get(API_ENDPOINTS.get.visits);
  }

  async startVisit(visitId, lat, lng, isoDateTime) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.post.startVisit, [
      [REPLACEMENTS.visitId, visitId],
    ]);

    return axiosInstance.post(endpoint, {
      lat,
      lng,
      dateTimeStarted: isoDateTime,
    });
  }

  async endVisit(visitId, isoDateTime) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.post.endVisit, [
      [REPLACEMENTS.visitId, visitId],
    ]);

    return axiosInstance.post(endpoint, {
      dateTimeEnded: isoDateTime,
    });
  }

  async sealVisit(visitId, isoDateTime) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.post.sealVisit, [
      [REPLACEMENTS.visitId, visitId],
    ]);

    return axiosInstance.post(endpoint, {
      dateTimeEnded: isoDateTime,
    });
  }

  async cancelVisit(visitId, isoDateTime) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.post.cancelVisit, [
      [REPLACEMENTS.visitId, visitId],
    ]);

    return axiosInstance.post(endpoint, {
      dateTimeEnded: isoDateTime,
    });
  }

  async postTextResponse(visitId, questionId, data) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.post.textResponse, [
      [REPLACEMENTS.visitId, visitId],
      [REPLACEMENTS.questionId, questionId],
    ]);

    return axiosInstance.post(endpoint, data);
  }

  async postChoiceResponse(visitId, questionId, data) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.post.choiceResponse, [
      [REPLACEMENTS.visitId, visitId],
      [REPLACEMENTS.questionId, questionId],
    ]);

    let payload = data;
    if (!Array.isArray(data)) {
      payload = [data];
    }
    return axiosInstance.post(endpoint, payload);
  }

  async postPhotoResponse(visitId, questionId, data) {
    const endpoint = this.getEndpoint(API_ENDPOINTS.post.photoResponse, [
      [REPLACEMENTS.visitId, visitId],
      [REPLACEMENTS.questionId, questionId],
    ]);

    return axiosInstance.post(endpoint, data);
  }
}

const dataService = new DataService();

export default dataService;
