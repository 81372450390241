import { Button } from "@redwigwam/redalerts.elements.button";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import LayoutBottom from "../components/Layout/LayoutBottom";
import LayoutTop from "../components/Layout/LayoutTop";
import Meta from "../components/Meta";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentQuestion,
  selectCurrentVisitLocationId,
  selectPreviousVisitLocationId,
  selectVisitsInCurrentVisitLocation,
  selectCurrentQuestionAnswer,
  selectPreviousAnswer,
  setAnswer,
  selectHelpLink,
  selectHelpText,
  selectHelpTitle,
  selectHelpVideoLink,
  setVisitStarted,
  setVisitFinished,
  selectAutoPromptHelp,
  selectIsQuestionOptional,
} from "../store/visit.slice";
import { selectIsSelectedQuestionDismissedModal } from "../store/settings.slice";

import HelpDrawer from "../components/HelpDrawer";
import Menu from "../components/Menu/Menu";

import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import styled from "styled-components";
import QuestionField, { QUESTION_TYPES } from "../components/Question";

import { dateToServerTimeString } from "../services/date";
import {
  endVisit,
  isVisitStarted,
  postAnswer,
  startVisit,
} from "../services/visit";
import {
  selectCurrentVisitId,
  selectPreviousVisitId,
  setCurrentCategory,
  setCurrentQuestionId,
  setPreviousVisitId,
} from "../store/settings.slice";
import { getLatLng } from "../services/geolocation";

const ShowHelpButton = styled(Button)`
  font-size: 12px;
  height: 24px;
  padding: 0 8px;
  box-shadow: 0px 2px 8px 0px #25101033;
  margin-bottom: 16px;
`;

const QuestionHeading = styled.h1`
  font-size: 24px;
  line-height: 29px;
  margin: 16px 0 8px 0;
  color: ${(props) => props.theme.colours.purple};
  font-weight: 600;
  text-align: center;
`;
const QuestionAdditionalText = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 8px 0;
  text-align: center;
`;

const QuestionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SubmitContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 48px;
`;

export const SubmitButton = styled(Button)`
  margin: 16px 0 0 0;
`;

const Question = () => {
  const [helpOpen, setHelpOpen] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentQuestion = useSelector(selectCurrentQuestion);
  const currentVisitId = useSelector(selectCurrentVisitId);
  const answer = useSelector(selectCurrentQuestionAnswer);

  const previousVisitId = useSelector(selectPreviousVisitId);

  const previousVisitLocationId = useSelector(selectPreviousVisitLocationId);

  const currentVisitLocationId = useSelector(selectCurrentVisitLocationId);

  const visitsInCurrentLocation = useSelector(
    selectVisitsInCurrentVisitLocation
  );

  const previousAnswer = useSelector(selectPreviousAnswer);

  const helplink = useSelector(selectHelpLink);
  const helptext = useSelector(selectHelpText);
  const helptitle = useSelector(selectHelpTitle);
  const videoLink = useSelector(selectHelpVideoLink);
  const autoPrompt = useSelector(selectAutoPromptHelp);
  const isModalDismissed = useSelector(selectIsSelectedQuestionDismissedModal);
  const isOptional = useSelector(selectIsQuestionOptional);

  const unstartedFollowOnVisits = visitsInCurrentLocation.filter(
    (visit) => !isVisitStarted(visit)
  );

  const [currentAnswer, setCurrentAnswer] = useState();

  const questionWrapRef = useRef();

  const {
    id: questionId,
    additionalText,
    questionType,
    question,
    metaData,
    canReuseAnswers,
  } = currentQuestion;

  useEffect(() => {
    if (previousAnswer && !answer && canReuseAnswers) {
      setCurrentAnswer(previousAnswer);
    }
  }, [answer, questionId, canReuseAnswers, previousAnswer]);

  useEffect(() => {
    if (
      autoPrompt &&
      !isModalDismissed &&
      (helplink || helptext || helptitle || videoLink)
    ) {
      setHelpOpen(true);
    }
  }, [
    autoPrompt,
    setHelpOpen,
    isModalDismissed,
    helplink,
    helptext,
    helptitle,
    videoLink,
  ]);

  useEffect(() => {
    let disabled = false;
    if (!isOptional) {
      // cases that define if a question is answered correctly or not when its required
      switch (questionType) {
        case QUESTION_TYPES.freeText:
          disabled = !currentAnswer?.freetext || currentAnswer?.freetext === "";
          break;
        default:
          disabled = !currentAnswer;
          break;
      }
    }

    setSubmitButtonDisabled(disabled);
  }, [currentAnswer, isOptional, questionType]);

  if (!questionId) {
    history.push("/");
    return null;
  }

  let choices = [];
  try {
    choices = JSON.parse(metaData);
  } catch (e) {
    console.error(e, metaData);
  }

  const scrollToSubmit = () => {
    setTimeout(() => {
      try {
        questionWrapRef.current.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      } catch (e) {
        console.error(e);
      }
    }, 100); // delay as layout may shift (photo retake button)
  };

  const handleChange = (answer) => {
    setCurrentAnswer(answer);

    if (questionType !== QUESTION_TYPES.checkbox) {
      scrollToSubmit();
    }
  };

  const handleSubmit = async () => {
    const {
      StartVisit,
      EndVisit,
      NextQuestion,
      BackToTasks,
      EndPreviousVisit,
      ShowFollowOnVisits,
      FollowOnVisitQuestion,
    } = currentAnswer;

    if (BackToTasks) {
      history.push("/");
      return;
    }

    const dateTime = dateToServerTimeString(new Date());

    if (EndPreviousVisit && previousVisitId) {
      dispatch(setVisitFinished({ visitId: previousVisitId, dateTime }));
      endVisit(previousVisitId, dateTime, dispatch);
    }

    if (StartVisit) {
      dispatch(setCurrentCategory());
      const { lat, lng } = await getLatLng();

      dispatch(
        setVisitStarted({ visitId: currentVisitId, lat, lng, dateTime })
      );
      startVisit(currentVisitId, lat, lng, dateTime, dispatch);

      if (currentVisitLocationId !== previousVisitLocationId) {
        dispatch(setPreviousVisitId());
      }
    }

    dispatch(
      setAnswer({
        questionId: questionId,
        visitId: currentVisitId,
        answer: currentAnswer,
        type: questionType,
      })
    );

    postAnswer(
      questionType,
      currentVisitId,
      questionId,
      currentAnswer,
      dispatch
    );

    setCurrentAnswer();

    if (EndVisit) {
      dispatch(setVisitFinished({ visitId: currentVisitId, dateTime }));
      endVisit(currentVisitId, dateTime, dispatch);
      history.push("/");
      return;
    }

    if (FollowOnVisitQuestion) {
      if (unstartedFollowOnVisits.length > 0) {
        dispatch(setPreviousVisitId(currentVisitId));

        dispatch(setCurrentQuestionId(FollowOnVisitQuestion));
        return;
      }
    }

    if (ShowFollowOnVisits) {
      history.push("/visit/follow-on");
      return;
    }

    if (NextQuestion) {
      dispatch(setCurrentQuestionId(NextQuestion));
    } else {
      history.push("/visit/steps");
      return;
    }
  };

  return (
    <>
      <Meta title="Question" />
      <LayoutTop>
        <Menu />
      </LayoutTop>
      <LayoutBottom>
        <QuestionWrap ref={questionWrapRef}>
          <QuestionHeading>{question}</QuestionHeading>
          <QuestionAdditionalText>{additionalText}</QuestionAdditionalText>

          {(helplink || helptext || helptitle || videoLink) && (
            <ShowHelpButton
              tertiary
              text="Show help"
              startIcon={<HelpOutlineOutlinedIcon />}
              onClick={() => setHelpOpen(true)}
            />
          )}

          <QuestionField
            choices={choices}
            questionType={questionType}
            answer={currentAnswer}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
          {questionType !== QUESTION_TYPES.photo && (
            <SubmitContainer>
              <SubmitButton
                onClick={handleSubmit}
                text="Confirm"
                disabled={submitButtonDisabled}
                primary
                startIcon={<CheckCircleOutlinedIcon />}
                fullWidth
              />
            </SubmitContainer>
          )}
        </QuestionWrap>

        <HelpDrawer show={helpOpen} setShow={setHelpOpen} />
      </LayoutBottom>
    </>
  );
};

export default Question;
