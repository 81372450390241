import axios from "axios";
import authService from "./auth";

const instance = axios.create({
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
  timeout: 15000,
});

instance.interceptors.request.use(
  async (config) => {
    const { token } = await authService.getAccessToken();
    if (token) {
      config.headers.authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {

    if (401 === error?.response?.status) {
      authService.logOut();
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
