import { shape, string, bool } from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteVisit,
  resetVisit,
  selectVisits,
  setVisitCancelled,
} from "../../store/visit.slice";
import {
  attemptCancelResync,
  attemptResync,
  cancelVisit,
} from "../../services/visit";

import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";
import KeyboardTabIcon from "@material-ui/icons/KeyboardTab";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";
import { Paper } from "@redwigwam/redalerts.elements.paper";
import {
  setCurrentCategory,
  setCurrentQuestionId,
  setCurrentVisitId,
} from "../../store/settings.slice";

import PermissionModal from "./PermissionModal";
import { checkAllPermissions } from "../../services/permissions";
import DeleteVisitModal from "./DeleteModal";
import ErrorModal from "./ErrorModal";
import ResetVisitModal from "./ResetModal";
import ContinueVisitModal from "./ContinueModal";
import CancelVisitModal from "./CancelModal";
import { dateToServerTimeString } from "../../services/date";

const MODES = {
  complete: "COMPLETE",
  selectable: "SELECTABLE",
  continue: "CONTINUE",
  finished: "FINISHED",
  errors: "ERRORS",
  sealed: "SEALED",
  cancelled: "CANCELLED",
  cancelledConfirmed: "CANCELLED-CONFIRMED",
};

const VisitContainer = styled(Paper)`
  display: grid;
  grid-template-columns: auto 30px;
  align-items: center;
  padding: 8px;
  cursor: pointer;

  ${(props) =>
    [MODES.finished, MODES.complete, MODES.errors].includes(props.mode) &&
    `
    color:${props?.theme?.colours?.black};
    `}

  ${(props) =>
    [MODES.selectable, MODES.continue].includes(props.mode) &&
    `
    color:white;
    background: ${props?.theme?.gradients?.purpleToPink};
    box-shadow:unset;
    `}

  ${(props) =>
    props.disabled &&
    `
    opacity:0.2;
    pointer-events:none;
    `}

    ${(props) =>
    [MODES.cancelled, MODES.cancelledConfirmed].includes(props.mode) &&
    `
      color:black;
      background: ${props?.theme?.colours?.salmon};
      box-shadow:unset;
      `}
`;

const VisitInfo = styled.div`
  flex-grow: 1;
  > p {
    margin: 0 0 4px 0;
  }
`;

const DisplayName = styled.p`
  font-family: "Montserrat";
  font-weight: 600;

  ${(props) =>
    [MODES.finished, MODES.complete, MODES.errors].includes(props.mode) &&
    `
    color:${props?.theme?.colours?.purple};
    `}
`;

const Address = styled.p`
  font-size: 14px;
  font-weight: 600;
`;
const Product = styled.p`
  font-size: 12px;
  font-weight: 600;
  opacity: 0.5;
`;

const Expires = styled.p`
  font-size: 12px;
`;

const spin = keyframes`
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

const SyncIcon = styled(SyncOutlinedIcon)`
  color: ${(props) => props?.theme?.colours?.purple};

  ${(props) =>
    props.loading &&
    css`
      animation: 1s ${spin} linear infinite;
    `}
`;

const SealedIcon = styled(CloudDoneOutlinedIcon)`
  color: #8bc34a;
`;

const ErrorIcon = styled(ReportProblemOutlinedIcon)`
  color: #eb4332;
`;

const DeleteIcon = styled(DeleteOutlinedIcon)`
  color: #eb4332;
`;

const VisitIcons = styled.div`
  padding-left: 8px;
  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props?.isCancelable ? "space-between" : "center"};
  align-items: flex-end;
  height: 100%;
  > svg {
    width: 24px;
  }
`;

const VisitDisplay = ({
  visit,
  started,
  finished,
  sealed,
  cancelled,
  cancelConfirmed,
  errors,
  disabled,
}) => {
  const {
    data: {
      id: visitId,
      visitType,
      plannedEndDate,
      location,
      product,
      questions = [],
    } = {},
    sealed: { dateTime: sealedDate } = {},
    cancelled: { dateTime: cancelledDate } = {},
  } = visit;
  const dispatch = useDispatch();
  const history = useHistory();

  const [resync, setResync] = useState(false);

  const [resetVisitOpen, setResetVisitOpen] = useState(false);
  const [continueVisitOpen, setContinueVisitOpen] = useState(false);
  const [errorsOpen, setErrorsOpen] = useState(false);
  const [permissionsOpen, setPermissionsOpen] = useState(false);
  const [deleteVisitOpen, setDeleteVisitOpen] = useState(false);
  const [cancelVisitOpen, setCancelVisitOpen] = useState(false);

  const [mode, setMode] = useState();

  const visits = useSelector(selectVisits);

  const isCancelable = !cancelled && !finished && !errors;

  const handleVisitSelect = async () => {
    setContinueVisitOpen(false);
    if (sealed) return;

    try {
      await checkAllPermissions();
    } catch (e) {
      setPermissionsOpen(true);
      return;
    }

    dispatch(setCurrentVisitId(visitId));

    const { id, category } = questions?.[0];

    if (!visits[visitId]?.answers?.[id]) {
      // first question is unanswered, jump to it
      dispatch(setCurrentQuestionId(id));
      dispatch(setCurrentCategory(category));
      history.push("/visit/question");
    } else {
      history.push("/visit/categories");
    }
  };

  const handleSync = async () => {
    setResync(true);
    await attemptResync(visit, dispatch);
    setResync(false);
  };

  const handleCancelSync = async () => {
    setResync(true);
    await attemptCancelResync(visit, dispatch);
    setResync(false);
  };

  const handleResetVisitConfirm = () => {
    setContinueVisitOpen(false);
    setResetVisitOpen(true);
  };

  const handleCancelVisitConfirm = () => {
    setContinueVisitOpen(true);
  };

  const handleRemove = () => {
    setContinueVisitOpen(false);
    setResetVisitOpen(false);
    dispatch(resetVisit(visitId));
    dispatch(setCurrentVisitId(null));
  };

  const handleErrors = () => {
    setErrorsOpen(true);
  };

  const handleDeleteConfirm = () => {
    setDeleteVisitOpen(true);
  };

  const handleDelete = () => {
    dispatch(deleteVisit(visitId));
  };

  const handleCancelConfirm = () => {
    setCancelVisitOpen(true);
  };

  const handleCancel = () => {
    setCancelVisitOpen(false);
    const dateTime = dateToServerTimeString(new Date());
    dispatch(setVisitCancelled({ visitId, dateTime }));
    dispatch(setCurrentVisitId(null));
    cancelVisit(visitId, dateTime, dispatch);
  };

  useEffect(() => {
    if (errors?.length > 0) {
      setMode(MODES.errors);
    } else {
      if (cancelled) {
        if (!cancelConfirmed) {
          setMode(MODES.cancelled);
        } else {
          setMode(MODES.cancelledConfirmed);
        }
      } else {
        if (sealed) {
          setMode(MODES.sealed);
        } else {
          if (!started) {
            setMode(MODES.selectable);
          } else {
            if (!finished) {
              setMode(MODES.continue);
            } else {
              setMode(MODES.finished);
            }
          }
        }
      }
    }
  }, [started, finished, errors, sealed, cancelled, cancelConfirmed]);

  const handleClick = () => {
    switch (mode) {
      case MODES.selectable:
        return handleVisitSelect();
      case MODES.continue:
        return handleCancelVisitConfirm();
      case MODES.finished:
        return handleSync();
      case MODES.errors:
        return handleErrors();
      case MODES.sealed:
        return handleDeleteConfirm();
      case MODES.cancelled:
        return handleCancelSync();
      case MODES.cancelledConfirmed:
        return handleDeleteConfirm();
      default:
        return null;
    }
  };

  const Icon = (props) => {
    switch (mode) {
      case MODES.sealed:
        return <SealedIcon {...props} />;
      case MODES.selectable:
        return <ArrowForwardIcon {...props} />;
      case MODES.continue:
        return <KeyboardTabIcon {...props} />;
      case MODES.finished:
        return <SyncIcon {...props} />;
      case MODES.errors:
        return <ErrorIcon {...props} />;
      case MODES.cancelledConfirmed:
        return <DeleteIcon {...props} />;
      case MODES.cancelled:
        return <SyncIcon {...props} />;
      default:
        return null;
    }
  };

  const prettyDate = (date) =>
    new Date(date).toLocaleDateString("en-gb", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    });

  const DisplayDate = () => {
    switch (mode) {
      case MODES.sealed:
        return `Completed: ${prettyDate(sealedDate)}`;
      case MODES.cancelled:
        return `Cancelled: ${prettyDate(cancelledDate)}`;
      case MODES.cancelledConfirmed:
        return `Cancelled: ${prettyDate(cancelledDate)}`;
      default:
        return `Expires: ${prettyDate(plannedEndDate)}`;
    }
  };

  return (
    <>
      <VisitContainer
        disabled={disabled}
        mode={mode}
        data-disabled={disabled}
        data-started={started}
        data-finished={finished}
        data-sealed={sealed}
        data-cancelled={cancelled}
        data-cancel-confirmed={cancelConfirmed}
        data-visit-id={visitId}
      >
        <VisitInfo onClick={handleClick}>
          <DisplayName>{visitType}</DisplayName>
          <Address>
            {location?.name}, {location?.postcode}
          </Address>
          {product?.name && <Product>{product?.name}</Product>}
          <Expires>{DisplayDate()}</Expires>
        </VisitInfo>
        <VisitIcons isCancelable={isCancelable}>
          {isCancelable && <CloseIcon onClick={handleCancelConfirm} />}
          <Icon loading={resync ? 1 : 0} onClick={handleClick} />
        </VisitIcons>
      </VisitContainer>

      <ContinueVisitModal
        open={continueVisitOpen}
        setOpen={setContinueVisitOpen}
        handleEnd={handleResetVisitConfirm}
        handleContinue={handleVisitSelect}
      />
      <ResetVisitModal
        open={resetVisitOpen}
        setOpen={setResetVisitOpen}
        handleReset={handleRemove}
      />

      <ErrorModal
        open={errorsOpen}
        setOpen={setErrorsOpen}
        errors={errors}
        handleRemove={handleRemove}
      />
      <PermissionModal open={permissionsOpen} setOpen={setPermissionsOpen} />
      <DeleteVisitModal
        open={deleteVisitOpen}
        setOpen={setDeleteVisitOpen}
        handleDelete={handleDelete}
      />
      <CancelVisitModal
        open={cancelVisitOpen}
        setOpen={setCancelVisitOpen}
        handleCancel={handleCancel}
      />
    </>
  );
};

VisitDisplay.propTypes = {
  visit: shape({
    id: string,
    displayName: string,
    plannedEndDate: string,
    plannedStartDate: string,
    location: shape({ name: string, address1: string, town: string }),
  }).isRequired,
  started: bool,
  finished: bool,
  sealed: bool,
  inProgress: bool,
};

VisitDisplay.defaultProps = {
  visit: {},
  started: false,
  finished: false,
  sealed: false,
  inProgress: true,
};

export default VisitDisplay;
