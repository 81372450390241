import ArrowForward from "@material-ui/icons/ArrowForward";
import { Button } from "@redwigwam/redalerts.elements.button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import LayoutBottom from "../components/Layout/LayoutBottom";
import LayoutTop from "../components/Layout/LayoutTop";
import Meta from "../components/Meta";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";

import {
  selectCurrentQuestions,
  selectIsCurrentCategoryComplete,
  selectCurrentAnswers,
} from "../store/visit.slice";

import {
  selectCurrentCategory,
  setCurrentQuestionId,
} from "../store/settings.slice";
import Menu from "../components/Menu/Menu";

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionButton = styled(Button)`
  margin-bottom: 16px;
  > div {
    justify-content: space-between;
    > span {
      display: flex;
      align-items: center;
      text-align: left;
    }
  }
`;

const ContinueButton = styled(Button)`
  margin-bottom: 48px;
`;

const CategoryTitle = styled.h1`
  color: ${(props) => props?.theme?.colours?.purple};
  font-weight: 600;
  text-align: center;
`;

const Steps = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const questions = useSelector(selectCurrentQuestions);

  const currentVisitAnswers = useSelector(selectCurrentAnswers);

  const currentCategoryName = useSelector(selectCurrentCategory);

  const isCategoryComplete = useSelector(selectIsCurrentCategoryComplete);

  const handleGoToQuestion = (questionId) => {
    dispatch(setCurrentQuestionId(questionId));
    history.push(`/visit/question`);
  };
  const questionsInCurrentCategory = questions.filter((question) => {
    const { category } = question;
    return category === currentCategoryName;
  });

  if (questionsInCurrentCategory.length === 0) {
    console.error("No steps, moving to categories");
    history.push("/visit/categories");
    return null;
  }

  const isAnswered = (questionId) => {
    return Boolean(currentVisitAnswers?.[questionId]?.answer);
  };

  const hasOutcome = (questionId) => {
    return (
      currentVisitAnswers?.[questionId]?.answer?.Intervention &&
      currentVisitAnswers?.[questionId]?.answer?.Outcome
    );
  };

  //const isConfirmed = (questionId) => {
  //return Boolean(currentVisitAnswers?.[questionId]?.serverConfirmed);
  //};

  return (
    <>
      <Meta title="Steps" />
      <LayoutTop>
        <Menu backButtonPath="/visit/categories" />
      </LayoutTop>
      <LayoutBottom>
        <CategoryTitle>{currentCategoryName}</CategoryTitle>
        <QuestionContainer>
          {questionsInCurrentCategory.map(({ id, subcategory }, index) => {
            const previousQuestion = questionsInCurrentCategory?.[index - 1];
            const isThisAnswered = isAnswered(id);
            const isPrevAnswered =
              isAnswered(previousQuestion?.id) || index === 0;

            const isThisHasOutcome = hasOutcome(id);

            const currentQuestion =
              !isThisAnswered && (isPrevAnswered || !previousQuestion);

            return (
              <QuestionButton
                disabled={!isPrevAnswered}
                secondary={!currentQuestion && isThisAnswered}
                primary={currentQuestion}
                key={id}
                text={subcategory}
                onClick={() => handleGoToQuestion(id)}
                endIcon={
                  <>
                    {!isThisAnswered ? (
                      <ArrowForward />
                    ) : isThisHasOutcome ? (
                      <DoneAllOutlinedIcon />
                    ) : (
                      <DoneOutlinedIcon />
                    )}
                  </>
                }
              />
            );
          })}
          <ContinueButton
            disabled={!isCategoryComplete}
            primary
            text="Continue"
            onClick={() => {
              history.push("/visit/categories");
            }}
          />
        </QuestionContainer>
      </LayoutBottom>
    </>
  );
};

export default Steps;
