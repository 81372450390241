import { func, string, shape } from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "@redwigwam/redalerts.elements.button";
import ReplayIcon from "@material-ui/icons/Replay";
import { CaptureIcon } from "./CaptureIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPreferredCameraId,
  setPreferredCameraId,
} from "../../store/settings.slice";
import FlipCameraAndroidOutlinedIcon from "@material-ui/icons/FlipCameraAndroidOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { getSquareScreenshotFromVideo } from "../../services/image";
import { CircularProgress } from "@material-ui/core";
import debounce from "lodash.debounce";
import { SubmitButton } from "../../pages/Question";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import InsertPhotoOutlinedIcon from "@material-ui/icons/InsertPhotoOutlined";

const CameraWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 300px;
`;

const WebcamWrap = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  background: black;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
`;

const WebcamContainer = styled.div`
  overflow: hidden;
  margin: 0 auto;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (orientation: landscape) {
    flex-direction: row;
    max-width: unset;
  }
`;

const VideoContainer = styled.div.attrs((props) => ({
  style: {
    maxHeight: `${props.shortestEdge}px`,
    maxWidth: `${props.shortestEdge}px`,
  },
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`;

const VideoWrap = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: ${(props) => (props.aspectRatio > 1 ? "row" : "column")};
`;

const StyledVideo = styled.video`
  ${(props) => (props.aspectRatio > 1 ? "height:100%" : "width:100%;")};
  &::-webkit-media-controls {
    display: none !important;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
`;

const StyledCanvas = styled.canvas`
  display: none;
  width: 512px;
`;

const AnswerPreview = styled.img`
  width: 100%;
  height: auto;
  margin: 0 auto 24px auto;
  border-radius: 8px;
  max-width: 75%;
`;

const actionButtonStyles = css`
  cursor: pointer;
  width: 24px;
  height: 24px;
  > svg {
    height: 100%;
    width: 100%;
    color: white;
    opacity: 0.8;
  }
`;

const CloseCameraButton = styled.div`
  ${actionButtonStyles}
`;

const CaptureButton = styled.div`
  ${actionButtonStyles}
  margin: 0 0 38px 0;
  width: 25%;
  height: auto;
  max-width: 100px;

  @media (orientation: landscape) {
    margin: 0 24px 0 8px;
  }
`;
const SwapCameraButton = styled.div`
  ${actionButtonStyles}
`;

const Loading = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  svg {
    color: white;
    opacity: 0.8;
  }
`;

const TopActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  @media (orientation: landscape) {
    flex-direction: column;
    justify-content: space-between;
    width: unset;
    height: 100%;
  }
`;

const Error = styled.div`
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const RetryButton = styled(Button)``;

const getOrientation = () => {
  if (window?.screen?.availHeight) {
    return window.screen.availHeight > window.screen.availWidth
      ? "portrait"
      : "landscape";
  } else {
    return null;
  }
};

const ImageQuestion = ({ choices, handleChange, answer, handleSubmit }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState();
  const [reloadCamera, setReloadCamera] = useState(false);

  const [streamSettings, setStreamSettings] = useState({});

  const [devices, setDevices] = useState([]);
  const [open, setOpen] = useState(false);

  const [shortestEdge, setShortestEdge] = useState(0);

  const dispatch = useDispatch();

  const containerRef = useRef();
  const webcamRef = useRef();
  const canvasRef = useRef();
  const preferredCameraId = useSelector(selectPreferredCameraId);

  const IMAGE_SIZE_SQUARE = 1024;

  const stopCamera = useCallback(
    () =>
      new Promise((resolve) => {
        if (webcamRef?.current?.srcObject?.getTracks?.()) {
          webcamRef.current.srcObject
            .getTracks()
            .forEach((track) => track.stop());
        }
        resolve();
      }),

    []
  );

  const capture = useCallback(() => {
    if (loaded) {
      const imageSrc = getSquareScreenshotFromVideo(
        canvasRef,
        webcamRef,
        IMAGE_SIZE_SQUARE
      );

      // merge metadata with answer
      const metaData = choices?.[0] ?? {};

      handleChange({
        ...metaData,
        photo: imageSrc,
        captureOrientation: getOrientation(),
      });
      stopCamera().then(() => {
        setOpen(false);
        toggleFullscreen();
      });
    }
  }, [webcamRef, handleChange, choices, stopCamera, loaded]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      const videoDevices = mediaDevices.filter(
        ({ kind }) => kind === "videoinput"
      );
      setDevices(videoDevices);
    },
    [setDevices]
  );

  const getVideoContainerWidth = () => {
    const { height, width } =
      containerRef?.current?.getBoundingClientRect?.() ?? {};
    setShortestEdge(Math.min(height, width));
  };

  const handleUserMediaError = useCallback((e) => {
    console.error(e);
    setError("Could not load camera");
  }, []);

  const handleUserMedia = useCallback(
    (src) => {
      // set <video/> stream
      webcamRef.current.srcObject = src;

      //display video
      setLoaded(true);

      //check for other cameras
      navigator.mediaDevices
        .enumerateDevices()
        .then(handleDevices)
        .catch(handleUserMediaError);
    },
    [handleDevices, handleUserMediaError]
  );

  useEffect(() => {
    const constraints = {
      video: {
        deviceId: { ideal: preferredCameraId },
        width: { ideal: IMAGE_SIZE_SQUARE },
        facingMode: { ideal: "environment" },
      },
      audio: false,
    };

    const grabWebcam = async () => {
      try {
        if (open) {
          setLoaded(false);
          await stopCamera();
          getVideoContainerWidth();
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          handleUserMedia(stream);
        }
      } catch (e) {
        handleUserMediaError(e);
      }
    };

    const grabWebcamDebounced = debounce(grabWebcam, 100);

    window.addEventListener("resize", grabWebcamDebounced);

    grabWebcam();

    return () => {
      window.removeEventListener("resize", grabWebcamDebounced);
    };
  }, [
    preferredCameraId,
    handleDevices,
    open,
    handleUserMedia,
    handleUserMediaError,
    stopCamera,
    reloadCamera,
  ]);

  const handleOnPlay = () => {
    const { width, height } = webcamRef?.current?.getBoundingClientRect() ?? {};
    setStreamSettings({ width, height, aspectRatio: width / height });
  };

  const toggleFullscreen = () => {
    if (document.fullscreenEnabled) {
      let element;
      if (document.fullscreenElement) {
        element = document;
        const cancelMethod =
          element.cancelFullScreen ||
          element.webkitCancelFullScreen ||
          element.mozCancelFullScreen ||
          element.exitFullscreen ||
          element.webkitExitFullscreen;

        cancelMethod.call(element);
      } else {
        element = document.documentElement;
        const requestMethod =
          element.requestFullScreen ||
          element.webkitRequestFullScreen ||
          element.mozRequestFullScreen ||
          element.msRequestFullScreen;

        requestMethod.call(element);
      }
    }
  };

  const handleOpen = () => {
    toggleFullscreen();
    setOpen(true);
  };

  const handleClose = () => {
    toggleFullscreen();
    stopCamera();
    setOpen(false);
  };

  const handleRetake = () => {
    setError();
    toggleFullscreen();
    handleChange();
    setOpen(true);
  };

  const changeCamera = () => {
    let nextDeviceId;
    if (preferredCameraId) {
      const currentIndex = devices.findIndex(
        (device) => device.deviceId === preferredCameraId
      );

      nextDeviceId =
        devices?.[currentIndex + 1]?.deviceId ?? devices?.[0]?.deviceId;
    } else {
      // choose second camera if no preference has been set yet.
      nextDeviceId = devices?.[1]?.deviceId;
    }

    dispatch(setPreferredCameraId(nextDeviceId));
  };

  return (
    <CameraWrap>
      {!answer && (
        <Button
          primary
          text="Take Photo"
          startIcon={<PhotoCameraOutlinedIcon />}
          onClick={handleOpen}
          fullWidth
        />
      )}
      {answer && (
        <>
          <AnswerPreview src={answer.photo} alt="answer image" />
          <Button
            onClick={handleRetake}
            text="Retake photo"
            startIcon={<ReplayIcon />}
            secondary
          />
          <SubmitButton
            primary
            onClick={handleSubmit}
            text="Accept Photo"
            fullWidth
            startIcon={<InsertPhotoOutlinedIcon />}
          />
        </>
      )}
      <WebcamWrap open={open}>
        <WebcamContainer id="webcam-container" ref={containerRef}>
          <TopActions>
            <CloseCameraButton onClick={handleClose}>
              <CloseOutlinedIcon />
            </CloseCameraButton>
            {devices.length > 1 && (
              <SwapCameraButton onClick={changeCamera}>
                <FlipCameraAndroidOutlinedIcon />
              </SwapCameraButton>
            )}
          </TopActions>

          <VideoContainer shortestEdge={shortestEdge}>
            <VideoWrap id="video-wrap" aspectRatio={streamSettings.aspectRatio}>
              {!loaded && (
                <>
                  {error ? (
                    <Error>
                      <p>{error}</p>
                      <RetryButton
                        text="Retry"
                        onClick={() => setReloadCamera((prev) => !prev)}
                        tertiary
                      />
                    </Error>
                  ) : (
                    <Loading>
                      <CircularProgress />
                    </Loading>
                  )}
                </>
              )}

              <StyledVideo
                id="camera"
                ref={webcamRef}
                autoPlay
                aspectRatio={streamSettings.aspectRatio}
                onPlay={handleOnPlay}
                playsInline
                muted
                controls={false}
              />

              <StyledCanvas
                ref={canvasRef}
                width={IMAGE_SIZE_SQUARE}
                height={IMAGE_SIZE_SQUARE}
              />
            </VideoWrap>
          </VideoContainer>

          <CaptureButton onClick={capture}>
            <CaptureIcon />
          </CaptureButton>
        </WebcamContainer>
      </WebcamWrap>
    </CameraWrap>
  );
};

ImageQuestion.propTypes = {
  handleChange: func.isRequired,
  answer: shape({ photo: string }),
};

export default ImageQuestion;
