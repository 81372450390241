import React from "react";

import MultipleChoiceMultipleAnswers from "./MultipleChoiceMultipleAnswers";
import ImageQuestion from "./ImageQuestion";
import MultipleChoiceOneAnswer from "./MultipleChoiceOneAnswer";
import FreeTextQuestion from "./FreeTextQuestion";
import UnexpectedQuestion from "./UnexpectedQuestion";
import MoreVisits from "./MoreVisits";

export const QUESTION_TYPES = {
  photo: "PHOTO",
  freeText: "FREE_TEXT",
  radio: "MULTI_CHOICE",
  checkbox: "MULTI_CHOICE_MULTI_SELECT",
  moreVisits: "MULTI_CHOICE_SHOW_MORE_VISITS",
};

const QuestionField = ({ questionType, ...rest }) => {
  let QuestionComponent;
  switch (questionType) {
    case QUESTION_TYPES.radio:
      QuestionComponent = MultipleChoiceOneAnswer;
      break;
    case QUESTION_TYPES.photo:
      QuestionComponent = ImageQuestion;
      break;
    case QUESTION_TYPES.checkbox:
      QuestionComponent = MultipleChoiceMultipleAnswers;
      break;
    case QUESTION_TYPES.freeText:
      QuestionComponent = FreeTextQuestion;
      break;
    case QUESTION_TYPES.moreVisits:
      QuestionComponent = MoreVisits;
      break;
    default:
      QuestionComponent = UnexpectedQuestion;
      break;
  }

  return <QuestionComponent {...rest} />;
};

export default QuestionField;
