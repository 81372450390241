export const getLatLng = () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      return getPosition()
        .then((position) => {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        })
        .catch((e) => {
          console.error(e);
          resolve({});
        });
    } else {
      resolve({});
    }
  });
};

export const getPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};
