import memoized from "memoizee";
import { QUESTION_TYPES } from "../components/Question";
import {
  addError,
  setAnswerServerConfirmed,
  setVisitCancelledServerConfirmed,
  setVisitFinishedServerConfirmed,
  setVisitInFlight,
  setVisitSealed,
  setVisitSealedServerConfirmed,
  setVisitStartedServerConfirmed,
} from "../store/visit.slice";
import dataService from "./data";
import { dateToServerTimeString } from "./date";

export const isVisitComplete = memoized((visit) => {
  const isStarted = isVisitStarted(visit) && isVisitStartedConfirmed(visit);

  const isFinished = isVisitFinished(visit) && isVisitFinishedConfirmed(visit);

  if (isStarted && isFinished) {
    return allQuestionsSynced(visit);
  }
  return false;
});

export const isVisitFinished = (visit) => {
  return Boolean(visit?.finished?.dateTime);
};

export const isVisitErrored = (visit) => {
  return visit?.errors?.length > 0;
};

export const isVisitFinishedConfirmed = (visit) => {
  return Boolean(visit?.finished?.serverConfirmed);
};

export const isVisitStarted = (visit) => {
  return Boolean(visit?.started?.dateTime);
};

export const isVisitStartedConfirmed = (visit) => {
  return Boolean(visit?.started?.serverConfirmed);
};

export const isVisitSealed = (visit) => {
  return Boolean(visit?.sealed?.dateTime);
};

export const isVisitSealedConfirmed = (visit) => {
  return Boolean(visit?.sealed?.serverConfirmed);
};

export const isVisitInFlight = (visit) => {
  return Boolean(visit?.inFlight);
};

export const isVisitCancelled = (visit) => {
  return Boolean(visit?.cancelled?.dateTime);
};

export const isVisitCancelledConfirmed = (visit) => {
  return Boolean(visit?.cancelled?.serverConfirmed);
};

export const isVisitExpired = (visit) => {
  return new Date() > new Date(visit?.data?.plannedEndDate);
};

// export const isVisitThisWeek = (visit) => {
//   const now = new Date();
//   isWithinInterval(new Date(visit?.data?.plannedEndDate), {
//     start: startOfWeek(now),
//     end: endOfWeek(now),
//   });
// };

export const allQuestionsSynced = (visit) => {
  const answers = visit?.answers ?? {};
  const questionIds = Object.keys(answers);

  return questionIds?.every?.(
    (questionId) => answers?.[questionId]?.serverConfirmed === true
  );
};

export const attemptResync = async (visit, dispatch) => {
  const {
    answers,
    started: {
      lat,
      lng,
      dateTime: startTime,
      serverConfirmed: startConfirmed,
    } = {},
    finished: { dateTime: finishTime, serverConfirmed: finishConfirmed } = {},
    sealed: { dateTime: sealedTime, serverConfirmed: sealedConfirmed } = {},
    data: { id: visitId } = {},
    errors,
  } = visit;

  console.log("[resync] - start", visitId);

  if (errors?.length > 0) {
    console.log("[resync] - Skipped due to errors", {
      visitId,
      errors,
    });
    return;
  }

  dispatch(setVisitInFlight({ visitId, inFlight: true }));

  let promises = [];
  // confirm start time / coords have been recieved
  if (!startConfirmed) {
    promises.push(
      new Promise((resolve, reject) => {
        startVisit(visitId, lat, lng, startTime, dispatch)
          .then(() => {
            resolve({ type: "Start" });
          })
          .catch((e) => {
            reject({ type: "Start", e });
          });
      })
    );
  } else {
    promises.push(
      Promise.resolve({ type: "Start", data: "Already Confirmed" })
    );
  }

  // confirm end time has been recieved
  if (!finishConfirmed) {
    promises.push(
      new Promise((resolve, reject) => {
        endVisit(visitId, finishTime, dispatch)
          .then(() => {
            resolve({ type: "Finish" });
          })
          .catch((e) => {
            reject({ type: "Finish", e });
          });
      })
    );
  } else {
    promises.push(
      Promise.resolve({ type: "Finish", data: "Already Confirmed" })
    );
  }

  // confirm every answer has been recieved
  Object.entries(answers).forEach(([questionId, value]) => {
    const { answer, serverConfirmed, type } = value;
    if (!serverConfirmed) {
      promises.push(
        new Promise((resolve, reject) => {
          postAnswer(type, visitId, questionId, answer, dispatch)
            .then(() => {
              resolve({
                type: "Question",
                questionType: type,
                questionId,
              });
            })
            .catch((e) => {
              reject({
                type: "Question",
                questionType: type,
                questionId,
                e,
              });
            });
        })
      );
    } else {
      promises.push(
        Promise.resolve({
          type: "Question",
          questionId,
          questionType: type,
          data: "Already Confirmed",
        })
      );
    }
  });

  // waits for all the promises to finish, wether they resolve or reject
  const results = await Promise.allSettled(promises);
  const allFufilled = results.every((result) => result.status === "fulfilled");

  console.log("[resync] - results", results);

  if (allFufilled && !sealedConfirmed) {
    let time = sealedTime;
    if (!time) {
      time = dateToServerTimeString(new Date());
      dispatch(setVisitSealed({ visitId, dateTime: time }));
    }
    try {
      await sealVisit(visitId, time, dispatch);
      setVisitSealedServerConfirmed({ visitId, serverConfirmed: true });
      console.log("[resync] Visit Sealed", visitId);
    } catch (e) {
      console.error("[resync] Error Sealing visit", visitId, e);
    }
  }

  dispatch(setVisitInFlight({ visitId, inFlight: false }));
};

export const endVisit = (visitId, dateTime, dispatch) => {
  return new Promise((resolve, reject) => {
    dataService
      .endVisit(visitId, dateTime)
      .then((apiResponse) => {
        const { success, response } = apiResponse?.data ?? {};
        if (
          success ||
          ["Visit already ended", "Visit is already sealed"].includes(response)
        ) {
          dispatch(
            setVisitFinishedServerConfirmed({
              visitId: visitId,
              serverConfirmed: true,
            })
          );
        }
        resolve();
      })
      .catch((e) => {
        const statusCode = e?.response?.response?.status;
        if (statusCode === 404) {
          // 404 means that visit ID is no longer valid
          dispatch(
            addError({
              visitId,
              error: {
                location: "Finish Visit",
                message: e?.response?.message,
              },
            })
          );
        }
        reject(e);
      });
  });
};

export const startVisit = async (visitId, lat, lng, dateTime, dispatch) => {
  return new Promise((resolve, reject) => {
    dataService
      .startVisit(visitId, lat, lng, dateTime)
      .then((apiResponse) => {
        const { success, response } = apiResponse?.data ?? {};
        if (
          success ||
          ["Visit already started", "Visit is already sealed"].includes(
            response
          )
        ) {
          dispatch(
            setVisitStartedServerConfirmed({
              visitId: visitId,
              serverConfirmed: true,
            })
          );
        }
        resolve();
      })
      .catch((e) => {
        const statusCode = e?.response?.response?.status;
        if (statusCode === 404) {
          // 404 means that visit ID is no longer valid
          dispatch(
            addError({
              visitId,
              error: { location: "Start Visit", message: e?.response?.message },
            })
          );
        }
        reject(e);
      });
  });
};

export const sealVisit = (visitId, dateTime, dispatch) => {
  return new Promise((resolve, reject) => {
    dataService
      .sealVisit(visitId, dateTime)
      .then((apiResponse) => {
        const { success, response } = apiResponse?.data ?? {};
        if (success || response === "Visit already sealed") {
          dispatch(
            setVisitSealedServerConfirmed({
              visitId: visitId,
              serverConfirmed: true,
            })
          );
        }
        resolve();
      })
      .catch((e) => {
        const statusCode = e?.response?.response?.status;
        if (statusCode === 404) {
          // 404 means that visit ID is no longer valid
          dispatch(
            addError({
              visitId,
              error: { location: "Seal Visit", message: e?.response?.message },
            })
          );
        }
        reject(e);
      });
  });
};

export const attemptCancelResync = async (visit, dispatch) => {
  const {
    cancelled: { dateTime: cancelTime } = {},
    data: { id: visitId } = {},
  } = visit;

  dispatch(setVisitInFlight({ visitId, inFlight: true }));

  try {
    await cancelVisit(visitId, cancelTime, dispatch);
  } catch (e) {
    console.error(e);
  }

  dispatch(setVisitInFlight({ visitId, inFlight: false }));
};

export const cancelVisit = (visitId, dateTime, dispatch) => {
  return new Promise((resolve, reject) => {
    dataService
      .cancelVisit(visitId, dateTime)
      .then((apiResponse) => {
        const { success, response, code } = apiResponse?.data ?? {};
        if (
          success ||
          response === "Visit already cancelled" ||
          code === "WRONG_USER"
        ) {
          dispatch(
            setVisitCancelledServerConfirmed({
              visitId: visitId,
              serverConfirmed: true,
            })
          );
        }
        resolve();
      })
      .catch((e) => {
        const statusCode = e?.response?.response?.status;
        if (statusCode === 404) {
          // 404 means that visit ID is no longer valid
          dispatch(
            addError({
              visitId,
              error: {
                location: "Cancel Visit",
                message: e?.response?.message,
              },
            })
          );
        }
        reject(e);
      });
  });
};

export const postAnswer = (
  questionType,
  visitId,
  questionId,
  answer,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    let postPromise;
    switch (questionType) {
      case QUESTION_TYPES.photo:
        postPromise = dataService.postPhotoResponse(
          visitId,
          questionId,
          answer
        );
        break;
      case QUESTION_TYPES.freeText:
        postPromise = dataService.postTextResponse(visitId, questionId, answer);
        break;
      default:
        postPromise = dataService.postChoiceResponse(
          visitId,
          questionId,
          answer
        );
    }

    postPromise
      .then(() => {
        dispatch(
          setAnswerServerConfirmed({
            visitId,
            questionId,
            serverConfirmed: true,
          })
        );
        resolve();
      })
      .catch((e) => {
        const statusCode = e?.response?.response?.status;
        if (statusCode === 404) {
          // 404 means that visit ID is no longer valid
          dispatch(
            addError({
              visitId,
              error: {
                location: "Question",
                questionId,
                message: e?.response?.message,
              },
            })
          );
        }
        reject(e);
      });
  });
};
