import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";

import { Wrapper } from "@redwigwam/redalerts.theme.wrapper";

import { store, persistor } from "./store";

import "./index.css";
import { PersistGate } from "redux-persist/integration/react";
import { setUpdateAvailable } from "./store/settings.slice";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Wrapper>
          <App />
        </Wrapper>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

const serviceWorkerConfig = {
  onUpdate: () => {
    store.dispatch(setUpdateAvailable(true));
  },
};

serviceWorkerRegistration.register(serviceWorkerConfig);
