import React from "react";
import styled from "styled-components";

import { height } from "./LayoutTop";

const Wrap = styled.div`
  height: calc(100% - ${height});
  background-color: rgba(37,16,16,0.05);
`;
const Container = styled.div`
  overflow-y: auto;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: 600px) {
    padding: 0 16px;
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    ::-webkit-scrollbar {
      width: 7px;
    }
  
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colours.purple};
    }
  
    ::-webkit-scrollbar-track {
      background: #E5D4E2;
    }
  }
`;

const LayoutBottom = ({ children, containerRef, ...rest }) => {
  return (
    <Wrap id="layout-bottom" {...rest}>
      <Container id="layout-bottom-container" ref={containerRef}>
        {children}
      </Container>
    </Wrap>
  );
};

export default LayoutBottom;
