import { createSelector, createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { data: {} },
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setAuthenticated, setUserData } = userSlice.actions;

const selectSlice = (state) => state?.[userSlice.name];

export const selectUserData = createSelector(
  selectSlice,
  (slice) => slice.data || {}
);

export const selectFullName = createSelector(
  selectUserData,
  (data) => data?.fullName
);

export const selectProfileUrl = createSelector(
  selectUserData,
  (data) => data?.profileUrl
);

export const LoggedInName = createSelector(
  selectUserData,
  (data) => data?.fullName?.split?.(" ")?.[0]
);

export const LoggedInEmail = createSelector(
  selectUserData,
  (data) => data.emailAddress || ""
);

export default userSlice;
