import { arrayOf, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import styled from "styled-components";

const StyledTextArea = styled.textarea`
  padding: 16px;
  border: 2px solid #c0baba;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
`;

const FreeTextQuestion = ({ choices, answer, handleChange }) => {
  const onChange = (e) => {
    handleChange({
      ...answer,
      freetext: e.target.value,
    });
  };

  useEffect(() => {
    // merge metadata with answer

    const metaData = choices?.[0] ?? {};
    handleChange({
      ...metaData,
      freetext: "",
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledTextArea
      value={answer?.freetext ?? ""}
      onChange={onChange}
      rows="12"
      columns="300"
      placeholder="Enter text (optional)"
    />
  );
};

FreeTextQuestion.propTypes = {
  choices: arrayOf(shape({})),
  answer: shape({ freetext: string }),
  handleChange: func.isRequired,
};

FreeTextQuestion.defaultProps = {
  choices: [],
  answer: { freetext: "" },
};

export default FreeTextQuestion;
