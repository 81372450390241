export const CaptureIcon = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle cx="24" cy="24" r="23" stroke="currentColor" strokeWidth="2" />
      <circle cx="24" cy="24" r="18" fill="currentColor" />
    </g>
  </svg>
);
