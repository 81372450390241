import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./pages/Login";
import Visits from "./pages/Visits";

import AuthRoute from "./components/AuthRoute";
import authService from "./services/auth";
import { setAuthenticated } from "./store/auth.slice";
import { setUserData } from "./store/user.slice";

import { useDispatch, useSelector } from "react-redux";
import LoadingSplash from "./pages/LoadingSplash";
import Steps from "./pages/Steps";
import Question from "./pages/Question";
import Page404 from "./pages/404";
import Categories from "./pages/Categories";
import FollowOnVisits from "./pages/FollowOnVisits";
import { selectRehydrated } from "./store";
import UpdateBar from "./components/UpdateBar";
import { clearVisits } from "./store/visit.slice";
import { clearSettings } from "./store/settings.slice";
import Debug from "./pages/Debug";

export const AuthContext = createContext(false);

const App = () => {
  const [authReady, setAuthReady] = useState(false);

  const dispatch = useDispatch();

  const forcedWaitDuration = 2000; //ms
  const [forcedWaitComplete, setForcedWaitComplete] = useState(false);

  const rehydrated = useSelector(selectRehydrated);

  useEffect(() => {
    const silentLogin = async () => {
      authService.registerListener((token) => {
        if (token) {
          dispatch(setAuthenticated(true));
        } else {
          dispatch(setAuthenticated(false));
          dispatch(clearVisits());
          dispatch(clearSettings());
          dispatch(setUserData());
        }
      });
      authService
        .silentLogIn()
        .then(() => {
          console.log("[AUTH] - Silent Success");
          dispatch(setAuthenticated(true));
        })
        .catch((e) => {
          const { token: { token, expiry } = {}, error } = e;
          console.error("[AUTH] - Silent Fail", error);
          if (token && new Date() > expiry) {
            console.log("[AUTH] - Token is still valid - Allow login");
            dispatch(setAuthenticated(true));
          } else {
            console.error("[AUTH] - Token missing or expired - Log out");
            authService.logOut();
          }
        })
        .finally(() => {
          setAuthReady(true);
        });
    };
    silentLogin();
  }, [dispatch]);

  // attempt to lock screen orientation to portrait
  useEffect(() => {
    try {
      window.screen.orientation
        .lock("portrait")
        .then(() => console.log("Screen locked to portrait"))
        .catch((e) => {
          console.error("Error locking screen orientation", e);
        });
    } catch (e) {
      console.error("Error locking screen orientation", e);
    }
  }, []);

  // show splashscreen some seconds

  useEffect(() => {
    setTimeout(() => {
      setForcedWaitComplete(true);
    }, forcedWaitDuration);
  }, []);

  return (
    <>
      <UpdateBar />

      {rehydrated && authReady && forcedWaitComplete ? (
        <Router>
          <Switch>
            <AuthRoute path="/" exact component={Visits} />
            <AuthRoute path="/visit/categories" exact component={Categories} />
            <AuthRoute path="/visit/steps" exact component={Steps} />
            <AuthRoute path="/visit/question" exact component={Question} />
            <AuthRoute
              path="/visit/follow-on"
              exact
              component={FollowOnVisits}
            />
            <Route path="/login" exact component={Login} />
            <Route path="/debug" exact component={Debug} />
            <Route component={Page404} />
          </Switch>
        </Router>
      ) : (
        <LoadingSplash />
      )}
    </>
  );
};

export default App;
