import React, { useEffect, useRef, useState } from "react";
import LayoutBottom from "../components/Layout/LayoutBottom";
import LayoutTop from "../components/Layout/LayoutTop";
import Meta from "../components/Meta";
import VisitDisplay from "../components/Visit/VisitDisplay";
import dataService from "../services/data";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { useSwipeable } from "react-swipeable";
import { CircularProgress } from "@material-ui/core";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";
import {
  attemptResync,
  isVisitComplete,
  isVisitFinished,
  isVisitInFlight,
  isVisitSealedConfirmed,
  isVisitStarted,
  isVisitCancelled,
  isVisitCancelledConfirmed,
  attemptCancelResync,
} from "../services/visit";

import {
  addVisit,
  deleteVisit,
  selectCancelledVisits,
  selectCurrentVisit,
  selectFinishedVisits,
  selectUnfinishedVisits,
  selectVisits,
} from "../store/visit.slice";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CheckIcon from "@material-ui/icons/Check";

import {
  selectCurrentVisitId,
  selectVisitLastUpdated,
  setLastUpdated,
} from "../store/settings.slice";
import Menu from "../components/Menu/Menu";
import { Paper } from "@redwigwam/redalerts.elements.paper";

const SwipeToRefreshContainer = styled.div`
  position: relative;
`;

const RefreshSpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0 16px 0;
`;

const VisitsContainer = styled.div`
  width: 100%;
  position: absolute;
  top: ${(props) => {
    if (props.$loading) {
      return `${props.$refreshHeight}px`;
    } else {
      return `${props.$deltaY}px`;
    }
  }};
`;

const VisitsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const LastUpdated = styled.p`
  font-size: 12px;
  text-align: center;
  opacity: 0.5;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

const CompletedToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${(props) => props.theme.colours.purple};
  padding-bottom: 8px;
  > p {
    font-weight: bold;
  }
`;

const NoVisits = styled.div`
  text-align: center;
  padding: 8px;
`;

const Header = styled.h1`
  color: ${(props) => props.theme.colours.purple};
  font-weight: 600;
`;

const NoVisitsIcon = styled(Paper)`
  border-radius: 50%;
  width: 125px;
  height: 125px;
  margin: 16px auto;

  svg {
    width: 75px;
    height: 75px;
    fill: ${(props) => props.theme.colours.purple};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.colours.purple} !important;
`;
const CompletedVisits = styled.div``;
const Visits = () => {
  const [deltaY, setDeltaY] = useState(0);
  const [loading, setLoading] = useState(false);
  const [triggeredRefresh, setTriggeredRefresh] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);

  const [swiping, setSwiping] = useState(false);
  const dispatch = useDispatch();
  const lastUpdated = useSelector(selectVisitLastUpdated);
  const visitPageContainerRef = useRef();
  const refreshSpinnerContainerRef = useRef();
  const visitsContainerRef = useRef();
  const visits = useSelector(selectVisits);
  const currentVisitId = useSelector(selectCurrentVisitId);
  const currentVisit = useSelector(selectCurrentVisit);

  const finishedVisits = useSelector(selectFinishedVisits);
  const unfinishedVisits = useSelector(selectUnfinishedVisits);
  const cancelledVisits = useSelector(selectCancelledVisits);

  const refreshHeight =
    refreshSpinnerContainerRef?.current?.getBoundingClientRect?.()?.height ?? 0;

  const handleSwiping = (e) => {
    setSwiping(true);
    const { dir, deltaY } = e;
    if (
      visitPageContainerRef?.current?.scrollTop === 0 &&
      dir === "Down" &&
      deltaY > 0 &&
      !loading
    ) {
      if (deltaY >= refreshHeight) {
        setLoading(true);
        setTriggeredRefresh((prev) => !prev);
      } else {
        setDeltaY(deltaY);
      }
    }
  };

  const handleSwiped = (e) => {
    setDeltaY(0);
    setSwiping(false);
  };

  const swipeHandlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwiped: handleSwiped,
  });

  const refPassthrough = (el) => {
    swipeHandlers.ref(el);
    visitsContainerRef.current = el;
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      dataService
        .getVisits()
        .then((response) => {
          const visitsList = response?.data;
          if (visitsList) {
            dispatch(setLastUpdated());
            setLoading(false);
            setDeltaY(0);

            visitsList.forEach((visit) => {
              dispatch(addVisit(visit));
            });
          } else {
            console.error("Unable to update visits", response);
            setLoading(false);
            setDeltaY(0);
          }
        })
        .catch((error) => {
          console.error("Unable to update visits", error);
          setLoading(false);
          setDeltaY(0);
        });

      finishedVisits.forEach((visit) => {
        if (!isVisitSealedConfirmed(visit) && !isVisitInFlight(visit)) {
          attemptResync(visit, dispatch);
        }
      });

      cancelledVisits.forEach((visit) => {
        if (!isVisitCancelledConfirmed(visit) && !isVisitInFlight(visit)) {
          attemptCancelResync(visit, dispatch);
        }
      });
    }

    // we want this to run on page load and on manual update. adding finishedVisits makes it go brrrrrr
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredRefresh, dispatch]);

  // remove expired and unstarted visits
  useEffect(() => {
    Object.entries(visits).forEach(([visitId, visit]) => {
      if (
        new Date(visit?.data?.plannedEndDate) < Date.now() &&
        !visit?.started?.dateTime
      ) {
        console.log("Unstarted Visit Expired - remove", visit);
        dispatch(deleteVisit(visitId));
      }
      if (
        ["COMPLETE", 6].includes(visit?.data?.status) &&
        !visit?.sealed?.dateTime
      ) {
        console.log("Visit already completed - remove", visit);
        dispatch(deleteVisit(visitId));
      }
    });
  }, [visits, dispatch]);

  useEffect(() => {
    try {
      let hidden;
      let visibilityChange;

      if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      }

      var document_hidden = document[hidden];

      const triggerRefreshOnTabFocus = () => {
        if (document_hidden !== document[hidden] && !document[hidden]) {
          setTriggeredRefresh((prev) => !prev);
        }
        document_hidden = document[hidden];
      };

      document.addEventListener(visibilityChange, () =>
        triggerRefreshOnTabFocus()
      );

      return document.removeEventListener(visibilityChange, () =>
        triggerRefreshOnTabFocus()
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const isCurrentVisitFinished = isVisitFinished(currentVisit);
  const isCurrentVisitStarted = isVisitStarted(currentVisit);

  // early end dates appear at top then group by location
  const sortedUnfinishedVisits = unfinishedVisits.sort((a, b) => {
    const aStarted = a?.started?.dateTime;
    const bStarted = b?.started?.dateTime;

    const aEndDate = new Date(a?.data?.plannedEndDate).getTime();
    const bEndDate = new Date(b?.data?.plannedEndDate).getTime();

    if (aStarted === bStarted) {
      if (aEndDate === bEndDate) {
        return a?.data?.location?.id.localeCompare(b?.data?.location?.id);
      }
      return aEndDate - bEndDate;
    }
    return aStarted === bStarted ? 0 : aStarted ? -1 : 1;
  });

  const sortedFinishedVisits = finishedVisits.sort(
    (a, b) => isVisitComplete(a) - isVisitComplete(b)
  );

  return (
    <>
      <Meta title="Tasks" />
      <LayoutTop>
        <Menu home />
      </LayoutTop>
      <LayoutBottom containerRef={visitPageContainerRef}>
        <VisitsPageContainer>
          <LastUpdated
            show={lastUpdated}
            onClick={() => setTriggeredRefresh((prev) => !prev)}
          >
            Last updated:{" "}
            {new Date(lastUpdated).toLocaleDateString("en-gb", {
              weekday: "short",
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })}
          </LastUpdated>
          <SwipeToRefreshContainer>
            <RefreshSpinnerContainer
              ref={refreshSpinnerContainerRef}
              onClick={() => setTriggeredRefresh((prev) => !prev)}
            >
              {loading && (
                <>
                  <StyledCircularProgress />
                  Updating...
                </>
              )}
              {!loading && swiping && (
                <>
                  <RefreshOutlinedIcon />
                  Refresh
                </>
              )}
            </RefreshSpinnerContainer>
            <VisitsContainer
              {...swipeHandlers}
              ref={refPassthrough}
              $deltaY={deltaY}
              $loading={loading}
              $refreshHeight={refreshHeight}
            >
              {sortedUnfinishedVisits.length === 0 && (
                <NoVisits>
                  <Header>You are all caught up</Header>
                  <span>
                    You have no new tasks available right now. Check back soon.
                  </span>
                  <NoVisitsIcon>
                    <CheckIcon />
                  </NoVisitsIcon>
                </NoVisits>
              )}
              {sortedUnfinishedVisits.length > 0 &&
                sortedUnfinishedVisits?.map?.((visit) => (
                  <VisitDisplay
                    visit={visit}
                    key={visit.data.id}
                    disabled={
                      visit.data.id !== currentVisitId &&
                      isCurrentVisitStarted &&
                      !isCurrentVisitFinished
                    }
                    started={isVisitStarted(visit)}
                    finished={isVisitFinished(visit)}
                  />
                ))}
              {sortedFinishedVisits.length > 0 && (
                <>
                  <CompletedToggle
                    onClick={() => setShowCompleted((prev) => !prev)}
                  >
                    {showCompleted ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    <p>
                      {`${showCompleted ? "Hide Completed" : "Show Completed"}`}
                    </p>
                  </CompletedToggle>

                  {showCompleted && (
                    <CompletedVisits>
                      {sortedFinishedVisits?.map?.((visit) => (
                        <VisitDisplay
                          visit={visit}
                          key={visit.data.id}
                          disabled={false}
                          started={isVisitStarted(visit)}
                          finished={isVisitFinished(visit)}
                          sealed={isVisitSealedConfirmed(visit)}
                          errors={visit.errors}
                        />
                      ))}
                    </CompletedVisits>
                  )}
                </>
              )}
              {cancelledVisits.length > 0 && (
                <>
                  <CompletedToggle
                    onClick={() => setShowCancelled((prev) => !prev)}
                  >
                    {showCancelled ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    <p>
                      {`${showCancelled ? "Hide Cancelled" : "Show Cancelled"}`}
                    </p>
                  </CompletedToggle>

                  {showCancelled && (
                    <CompletedVisits>
                      {cancelledVisits?.map?.((visit) => (
                        <VisitDisplay
                          visit={visit}
                          key={visit.data.id}
                          disabled={false}
                          cancelled
                          cancelConfirmed={isVisitCancelledConfirmed(visit)}
                        />
                      ))}
                    </CompletedVisits>
                  )}
                </>
              )}
            </VisitsContainer>
          </SwipeToRefreshContainer>
        </VisitsPageContainer>
      </LayoutBottom>
    </>
  );
};
export default Visits;
