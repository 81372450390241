import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@redwigwam/redalerts.elements.button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import authService from "../../services/auth";
import { useSelector } from "react-redux";
import { LoggedInName, LoggedInEmail } from "../../store/user.slice";
import Modal from "../Modal";
import IDBadge from "./IDBadge";
import { selectCurrentVisitData } from "../../store/visit.slice";
import { useHistory } from "react-router";

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props?.theme?.colours?.purple};
  touch-action: none;
  transition: opacity 0.2s;
  top: ${(props) => !props.show && "100%"};
`;

const DrawerContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  overflow: hidden;
  transition: bottom 0.2s, top 0.2s;
`;

const CloseWrap = styled.div`
  position: relative;
  height: 24px;
  text-align: right;
`;
const ContentWrap = styled.div`
  overflow-y: auto;
  touch-action: pan-y; /* ensures that background is not scrolled*/
  height: calc(100% - 24px);
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`;

const MenuButton = styled(Button)`
  width: 100%;
  margin: 6px 0;
`;

const CloseButton = styled(CloseIcon)`
  color: ${(props) => props?.theme?.colours?.white};
`;

const Container = styled.div`
  font-weight: 500;
  color: ${(props) => props?.theme?.colours?.white};
`;

const HelloHeader = styled.h1`
  font-size: 24px;
  font-weight: 600;
`;

const HelloText = styled.h2`
  font-weight: 16px;
`;

const LoggedIn = styled.div`
  color: ${(props) => props?.theme?.colours?.white};
  opacity: 0.5;
  margin-bottom: 16px;
`;

const AppVersion = styled.div`
  color: ${(props) => props?.theme?.colours?.white};
  opacity: 0.25;
`;

const ModalTitle = styled.h2`
  color: ${(props) => props?.theme?.colours?.purple};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

const ModalText = styled.p`
  hyphens: auto;
  text-align: justify;
`;

const ModalButton = styled(Button)`
  padding: 0 36px;
`;

const ModalButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const VersionWrap = styled.div`
  user-select: none;
`;

const MenuDrawer = ({ show, setShow }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showIDBadge, setShowIdBadge] = useState(false);

  const [debugCount, setDebugCount] = useState(0);
  const debugTimeout = useRef();

  const history = useHistory();

  const name = useSelector(LoggedInName);
  const email = useSelector(LoggedInEmail);

  const visit = useSelector(selectCurrentVisitData);

  const handleLogout = () => {
    authService.logOut();
  };

  const handleClose = () => {
    if (showIDBadge) {
      setShowIdBadge(false);
    } else {
      setShow(false);
    }
  };

  const triggerDebug = () => {
    clearTimeout(debugTimeout.current);
    setDebugCount((prev) => prev + 1);
    if (debugCount >= 10) {
      setDebugCount(0);
      history.push("/debug");
      setShow(false);
    } else {
      debugTimeout.current = setTimeout(() => {
        setDebugCount(0);
      }, 1000);
    }
  };

  useEffect(() => {
    return clearTimeout(debugTimeout.current);
  }, []);

  const buildDate = process?.env?.REACT_APP_BUILD_DATE;
  return (
    <Overlay show={show}>
      <DrawerContainer show={show}>
        <CloseWrap>
          <CloseButton onClick={handleClose} />
        </CloseWrap>
        <ContentWrap>
          {showIDBadge ? (
            <IDBadge />
          ) : (
            <>
              <Container>
                <HelloHeader>Hello {name}</HelloHeader>
                <HelloText>What are you looking for?</HelloText>
              </Container>
              <div>
                <MenuButton
                  tertiary
                  text="ID Badge"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => setShowIdBadge(true)}
                  disabled={!visit?.id}
                />
                <MenuButton
                  tertiary
                  text="Logout"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => setShowLogoutModal(true)}
                />
              </div>
              <VersionWrap onClick={triggerDebug}>
                <LoggedIn>Logged in as {email}</LoggedIn>
                {buildDate && <AppVersion>Version: {buildDate}</AppVersion>}
              </VersionWrap>
            </>
          )}
        </ContentWrap>
      </DrawerContainer>
      <Modal open={showLogoutModal}>
        <ModalTitle>Are you sure you want to logout?</ModalTitle>
        <ModalText>
          All your task information saved locally will be permanently removed
          from this device. This includes any recordings for tasks you have in
          progress and also any completed tasks not yet synchronised with our
          servers.
        </ModalText>
        <ModalButtonWrap>
          <ModalButton
            secondary
            text="Cancel"
            onClick={() => setShowLogoutModal(false)}
          />
          <ModalButton primary text="Yes" onClick={handleLogout} />
        </ModalButtonWrap>
      </Modal>
    </Overlay>
  );
};

export default MenuDrawer;
