import ArrowForward from "@material-ui/icons/ArrowForward";
import { Button } from "@redwigwam/redalerts.elements.button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import LayoutBottom from "../components/Layout/LayoutBottom";
import LayoutTop from "../components/Layout/LayoutTop";
import Meta from "../components/Meta";
import Menu from "../components/Menu/Menu";

import {
  selectIsCurrentVisitFinished,
  selectCategoryProgress,
  selectVisitType,
} from "../store/visit.slice";

import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { setCurrentCategory, setCurrentVisitId } from "../store/settings.slice";

const FinishButton = styled(Button)``;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryButton = styled(Button)`
  margin-bottom: 16px;

  > div {
    justify-content: space-between;
    > span {
      display: flex;
      align-items: center;
    }
  }
`;

const ProgressContainer = styled.span`
  margin-right: 8px;
  font-size: 12px;
`;

const VisitTitle = styled.h1`
  color: ${(props) => props?.theme?.colours?.purple};
  font-weight: 600;
  text-align: center;
`;

const Categories = () => {
  const history = useHistory();
  const categoryProgress = useSelector(selectCategoryProgress);

  const isVisitFinished = useSelector(selectIsCurrentVisitFinished);
  const visitType = useSelector(selectVisitType);

  const dispatch = useDispatch();

  const handleSelectCategory = (name) => {
    dispatch(setCurrentCategory(name));
    history.push("/visit/steps");
  };

  const handleFinish = () => {
    dispatch(setCurrentVisitId(null));
    history.push("/");
  };

  if (categoryProgress?.length === 0) {
    history.push("/");
  }

  return (
    <>
      <Meta title="Categories" />
      <LayoutTop>
        <Menu backButtonPath="/" />
      </LayoutTop>

      <LayoutBottom>
        <VisitTitle>{visitType} visit</VisitTitle>
        <CategoryContainer>
          {categoryProgress.map(
            ({ name, steps, completed, withOutcome }, index) => {
              const { steps: prevSteps, completed: prevCompleted } =
                categoryProgress?.[index - 1] ?? {};

              const currentCategory =
                steps !== completed && prevSteps === prevCompleted;

              return (
                <CategoryButton
                  key={name}
                  primary={currentCategory}
                  secondary={!currentCategory}
                  onClick={() => handleSelectCategory(name, index)}
                  text={name}
                  disabled={!currentCategory && steps !== completed}
                  endIcon={
                    <>
                      {steps === completed ? (
                        <>
                          {withOutcome ? (
                            <DoneAllOutlinedIcon />
                          ) : (
                            <DoneOutlinedIcon />
                          )}
                        </>
                      ) : (
                        <>
                          <ProgressContainer>{`${completed}/${steps}`}</ProgressContainer>
                          <ArrowForward />
                        </>
                      )}
                    </>
                  }
                />
              );
            }
          )}
        </CategoryContainer>

        {isVisitFinished && (
          <FinishButton
            primary
            onClick={handleFinish}
            text="Finish"
            startIcon={<CheckCircleOutlineOutlinedIcon />}
            fullWidth
          />
        )}
      </LayoutBottom>
    </>
  );
};

export default Categories;
