import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  isVisitCancelled,
  isVisitErrored,
  isVisitExpired,
  isVisitFinished,
  isVisitStarted,
} from "../services/visit";
import {
  selectCurrentCategory,
  selectCurrentQuestionId,
  selectCurrentVisitId,
  selectPreviousVisitId,
} from "./settings.slice";

const initialState = {};

const visitSlice = createSlice({
  name: "visit",
  initialState,
  reducers: {
    addVisit: (state, action) => {
      const visit = action.payload;

      if (!state[visit.id]) {
        state[visit.id] = {
          data: visit,
          answers: {},
          started: {
            dateTime: null,
            lat: null,
            lng: null,
            serverConfirmed: false,
          },
          finished: {
            dateTime: false,
            serverConfirmed: false,
          },
          sealed: {
            dateTime: false,
            serverConfirmed: false,
          },
          cancelled: {
            dateTime: false,
            serverConfirmed: false,
            reason: null,
          },
          inFlight: false,
          errors: [],
        };
      } else {
        state[visit.id].data = visit;
      }
    },
    deleteVisit: (state, action) => {
      const visitId = action.payload;
      delete state[visitId];
    },
    setVisitStarted: (state, action) => {
      try {
        const { visitId, lat, lng, dateTime } = action.payload;

        state[visitId].started.dateTime = dateTime;
        state[visitId].started.lat = lat;
        state[visitId].started.lng = lng;
      } catch (e) {
        console.error(e);
      }
    },
    setVisitStartedServerConfirmed: (state, action) => {
      try {
        const { visitId, serverConfirmed } = action.payload;
        state[visitId].started.serverConfirmed = serverConfirmed;
      } catch (e) {
        console.error(e);
      }
    },

    setAnswer: (state, action) => {
      try {
        const { questionId, visitId, answer, type } = action.payload;
        state[visitId].answers[questionId] = {
          answer,
          type,
          serverConfirmed: false,
        };
      } catch (e) {
        console.error(e);
      }
    },

    setAnswerServerConfirmed: (state, action) => {
      try {
        const { questionId, visitId, serverConfirmed } = action.payload;
        state[visitId].answers[questionId].serverConfirmed = serverConfirmed;
      } catch (e) {
        console.error(e);
      }
    },

    setVisitFinished: (state, action) => {
      try {
        const { visitId, dateTime } = action.payload;
        state[visitId].finished.dateTime = dateTime;
      } catch (e) {
        console.error(e);
      }
    },

    setVisitFinishedServerConfirmed: (state, action) => {
      try {
        const { visitId, serverConfirmed } = action.payload;
        state[visitId].finished.serverConfirmed = serverConfirmed;
      } catch (e) {
        console.error(e);
      }
    },
    setVisitSealed: (state, action) => {
      try {
        const { visitId, dateTime } = action.payload;
        state[visitId].sealed.dateTime = dateTime;
      } catch (e) {
        console.error(e);
      }
    },

    setVisitSealedServerConfirmed: (state, action) => {
      try {
        const { visitId, serverConfirmed } = action.payload;
        state[visitId].sealed.serverConfirmed = serverConfirmed;
      } catch (e) {
        console.error(e);
      }
    },

    setVisitCancelled: (state, action) => {
      try {
        const { visitId, dateTime, reason } = action.payload;
        state[visitId].cancelled.dateTime = dateTime;
        state[visitId].cancelled.reason = reason;
      } catch (e) {
        console.error(e);
      }
    },

    setVisitCancelledServerConfirmed: (state, action) => {
      try {
        const { visitId, serverConfirmed } = action.payload;
        state[visitId].cancelled.serverConfirmed = serverConfirmed;
      } catch (e) {
        console.error(e);
      }
    },

    setVisitInFlight: (state, action) => {
      try {
        const { visitId, inFlight } = action.payload;
        state[visitId].inFlight = inFlight;
      } catch (e) {
        console.error(e);
      }
    },

    addError: (state, action) => {
      const { visitId, error } = action.payload;
      state[visitId].errors = [...state[visitId].errors, error];
    },
    resetVisit: (state, action) => {
      const visitId = action.payload;

      state[visitId].answers = {};
      state[visitId].started = {
        dateTime: null,
        lat: null,
        lng: null,
        serverConfirmed: false,
      };
      state[visitId].errors = [];

      state[visitId].finished = { dateTime: false, serverConfirmed: false };
      state[visitId].cancelled = {
        dateTime: false,
        serverConfirmed: false,
        reason: null,
      };
    },
    clearVisits: () => initialState,
  },
});

export const {
  setVisitStarted,
  setVisitStartedServerConfirmed,
  setAnswer,
  setAnswerServerConfirmed,
  setVisitFinished,
  setVisitFinishedServerConfirmed,
  setVisitSealed,
  setVisitSealedServerConfirmed,
  setVisitInFlight,
  setVisitCancelled,
  setVisitCancelledServerConfirmed,
  addVisit,
  resetVisit,
  clearVisits,
  addError,
  deleteVisit,
} = visitSlice.actions;

export const selectVisits = (state) => state?.[visitSlice.name] ?? {};

export const selectCurrentVisit = createSelector(
  selectVisits,
  selectCurrentVisitId,
  (visits, visitId) => {
    return visits?.[visitId] ?? {};
  }
);
export const selectPreviousVisit = createSelector(
  selectVisits,
  selectPreviousVisitId,
  (visits, previousVisitId) => {
    return visits?.[previousVisitId];
  }
);

export const selectCurrentVisitData = createSelector(
  selectCurrentVisit,
  (visit) => {
    return visit?.data ?? {};
  }
);

export const selectPreviousVisitData = createSelector(
  selectPreviousVisit,
  (visit) => {
    return visit?.data ?? {};
  }
);

export const selectCurrentQuestions = createSelector(
  selectCurrentVisitData,
  (visit) => visit?.questions ?? []
);

export const selectCurrentQuestion = createSelector(
  selectCurrentQuestions,
  selectCurrentQuestionId,
  (questions, currentQuestionId) =>
    questions?.find((question) => question.id === currentQuestionId) ?? {}
);

export const selectCurrentCategories = createSelector(
  selectCurrentVisitData,
  (visit) => visit?.questionCategories ?? []
);

export const selectCurrentLocation = createSelector(
  selectCurrentVisitData,
  (visit) => visit?.location ?? {}
);

export const selectPreviousLocation = createSelector(
  selectPreviousVisitData,
  (visit) => visit?.location ?? {}
);

export const selectCurrentVisitLocationId = createSelector(
  selectCurrentLocation,
  (location) => location?.id
);

export const selectPreviousVisitLocationId = createSelector(
  selectPreviousLocation,
  (location) => location?.id
);

export const selectVisitsInCurrentVisitLocation = createSelector(
  selectCurrentVisitLocationId,
  selectCurrentVisitId,
  selectVisits,
  (currentVisitLocationId, currentVisitId, visits) => {
    return Object.keys(visits)
      .filter((visitId) => {
        const visit = visits?.[visitId].data ?? {};
        const sameLocation = visit?.location?.id === currentVisitLocationId;
        const notCurrentVisit = visit?.id !== currentVisitId;
        const notExpired = !isVisitExpired(visits[visitId]);
        return sameLocation && notCurrentVisit && notExpired;
      })
      .map((visitId) => visits[visitId]);
  }
);

export const selectCurrentStore = createSelector(
  selectCurrentLocation,
  (location) => location?.name
);

export const selectCurrentProduct = createSelector(
  selectCurrentVisitData,
  (visit) => visit?.product ?? {}
);

export const selectCurrentProdName = createSelector(
  selectCurrentProduct,
  (product) => product?.name
);

export const selectCurrentSKU = createSelector(
  selectCurrentProduct,
  (product) => product?.sku
);

export const selectCurrentAnswers = createSelector(
  selectCurrentVisit,
  (visit) => visit?.answers ?? {}
);

export const selectCurrentQuestionAnswer = createSelector(
  selectCurrentAnswers,
  selectCurrentQuestionId,
  (answers, questionId) => {
    return answers?.[questionId]?.answer ?? null;
  }
);

export const selectIsCurrentVisitFinished = createSelector(
  selectCurrentVisit,
  (visit) => Boolean(visit?.finished?.dateTime)
);

export const selectIsCurrentVisitSealed = createSelector(
  selectCurrentVisit,
  (visit) => Boolean(visit?.sealed?.dateTime)
);

export const selectPreviousAnswer = createSelector(
  selectCurrentQuestionId,
  selectPreviousVisit,
  (questionId, previousVisit) => previousVisit?.answers?.[questionId]?.answer
);

export const selectCategoryProgress = createSelector(
  selectCurrentAnswers,
  selectCurrentVisitData,
  (answers, visit) => {
    const { questionCategories = [], questions = [] } = visit;

    return questionCategories
      .filter(({ excludeFromCategoryList }) => !excludeFromCategoryList)
      .map(({ category }) => {
        const questionsInCategory = questions.filter(
          (question) => question.category === category
        );

        const completedQuestions = questionsInCategory.filter(
          ({ id: questionId }) => answers?.[questionId]
        );

        const completedWithOutcome = completedQuestions.some(
          ({ id: questionId }) =>
            answers?.[questionId]?.answer?.Outcome &&
            answers?.[questionId]?.answer?.Intervention
        );

        return {
          name: category,
          steps: questionsInCategory.length,
          completed: completedQuestions.length,
          withOutcome: completedWithOutcome,
        };
      });
  }
);

export const selectIsCurrentCategoryComplete = createSelector(
  selectCurrentCategory,
  selectCategoryProgress,
  (name, progress) => {
    const { steps = 10, completed = 1 } =
      progress?.find((category) => category.name === name) ?? {};

    return steps === completed;
  }
);

export const selectVisitType = createSelector(
  selectCurrentVisitData,
  (visit) => visit?.visitType
);

export const selectCurrentCategoryData = createSelector(
  selectCurrentCategory,
  selectCurrentVisitData,

  (name, visit) =>
    visit?.questionCategories?.find((category) => category.category === name) ??
    {}
);

export const selectIsCurrentCategoryExcluded = createSelector(
  selectCurrentCategoryData,

  (data) => data?.excludeFromCategoryList
);

export const selectAutoPromptHelp = createSelector(
  selectCurrentQuestion,
  (question) => question?.autoPromptHelp
);

export const selectHelpLink = createSelector(
  selectCurrentQuestion,
  (question) => question?.helpLink
);

export const selectHelpText = createSelector(
  selectCurrentQuestion,
  (question) => question?.helpText
);

export const selectHelpTitle = createSelector(
  selectCurrentQuestion,
  (question) => question?.helpTitle
);

export const selectHelpVideoLink = createSelector(
  selectCurrentQuestion,
  (question) => question?.helpVideoLink
);

export const selectIsQuestionOptional = createSelector(
  selectCurrentQuestion,
  (question) => question?.allowNoInput
);

export const selectFinishedVisits = createSelector(selectVisits, (visits) => {
  const output = [];
  Object.entries(visits).forEach(([visitId, visit]) => {
    if (
      !isVisitCancelled(visit) &&
      (isVisitFinished(visit) || isVisitErrored(visit))
    ) {
      output.push(visit);
    }
  });
  return output;
});

export const selectUnfinishedVisits = createSelector(selectVisits, (visits) => {
  const output = [];
  Object.entries(visits).forEach(([visitId, visit]) => {
    if (
      !isVisitCancelled(visit) &&
      !isVisitFinished(visit) &&
      !isVisitErrored(visit) &&
      (!isVisitExpired(visit) ||
        (isVisitExpired(visit) && isVisitStarted(visit)))
    ) {
      output.push(visit);
    }
  });
  return output;
});

export const selectCancelledVisits = createSelector(selectVisits, (visits) => {
  const output = [];
  Object.entries(visits).forEach(([visitId, visit]) => {
    if (isVisitCancelled(visit)) {
      output.push(visit);
    }
  });
  return output;
});

export const selectCurrentVisitErrors = createSelector(
  selectCurrentVisit,
  (visit) => visit?.errors ?? []
);

export default visitSlice;
