import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import styled from "styled-components";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import { Button } from "@redwigwam/redalerts.elements.button";
import { OpenInNewOutlined } from "@material-ui/icons";
import {
  isChrome,
  isEdge,
  isFirefox,
  isOpera,
  isSafari,
} from "react-device-detect";
import ArrowForward from "@material-ui/icons/ArrowForward";

const PermissionErrorIcon = styled(ReportProblemOutlined)`
  width: 48px !important;
  height: 48px !important;
  color: ${(props) => props?.theme?.colours?.red};
  margin-bottom: 16px;
  align-self: center;
`;

const PermissionWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin: 8px 0;
  }
`;

const BackButton = styled(Button)`
  margin-top: 16px;
`;

const HelpLink = styled.a`
  svg {
    font-size: inherit;
  }
`;

const PermissionList = styled.ul`
  margin: 0;
`;

const PermissionModal = ({ open, setOpen }) => {
  const [helpLink, setHelpLink] = useState();
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let link;

    if (isChrome) {
      link = "https://support.google.com/chrome/answer/2693767";
    } else if (isFirefox) {
      link =
        "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#w_changing-camera-permissions";
    } else if (isSafari) {
      link = "https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac";
    } else if (isOpera) {
      link = "https://help.opera.com/en/latest/web-preferences/#camera";
    } else if (isEdge) {
      link =
        "https://www.windowscentral.com/how-manage-site-permissions-new-microsoft-edge#set_site_permissions_edge_chromium";
    }
    setHelpLink(link);
  }, []);

  return (
    <Modal open={open}>
      <PermissionWrap>
        <PermissionErrorIcon />
        <p>The following permissions are required:</p>
        <PermissionList>
          <li>Camera</li>
          <li>Location</li>
        </PermissionList>
        <p>Please allow these permissions in your devices settings</p>
        {helpLink && (
          <p>
            For information on how to do this,{" "}
            <HelpLink href={helpLink} target="_blank" rel="noreferrer">
              click here <OpenInNewOutlined />
            </HelpLink>
          </p>
        )}

        <BackButton
          text="Back to tasks"
          primary
          fullWidth
          onClick={handleClose}
          endIcon={<ArrowForward />}
        />
      </PermissionWrap>
    </Modal>
  );
};

export default PermissionModal;
